<template>
    <component v-if="layout" :is="layout.component" :key="layout.name" />
</template>

<script setup lang="ts">
import { Layout } from "@/types/Layout";
import { type Ref, watch, shallowRef } from "vue";
import { useRoute } from "vue-router";
import Default from "./types/Default.vue";
import Sidebar from "./types/Sidebar.vue";

const Layouts: { [key: string]: Layout } = {
    Default: {
        name: "Default",
        component: Default,
    },
    Sidebar: {
        name: "Sidebar",
        component: Sidebar,
    },
};

const route = useRoute();
const layout: Ref<Layout | null> = shallowRef(null);

watch(
    route,
    (newValue) => {
        // Get layout to render
        if (
            newValue.meta.layout &&
            newValue.meta.layout !== layout.value?.name
        ) {
            layout.value = Layouts[newValue.meta.layout];
        }
    },
    { immediate: true },
);
</script>
