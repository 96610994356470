<template>
    <div v-if="fields && form.fields" class="form-grid">
        <div v-for="field in fields" :key="field.name">
            <label :for="field.name">
                {{ field.label }}
            </label>

            <!-- Text -->
            <v-text-field
                v-if="field.type === 'text'"
                v-model="form.fields[field.name].model"
                v-bind="form.fields[field.name].props"
                :id="field.name"
                :name="field.name"
            ></v-text-field>

            <!-- Select -->
            <v-select
                v-if="field.type === 'select'"
                v-model="form.fields[field.name].model"
                v-bind="form.fields[field.name].props"
                :id="field.name"
                :name="field.name"
                :items="field.items"
                variant="outlined"
                item-title="name"
                item-value="id"
            ></v-select>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Field, FieldsEbanx } from "@/types/PaymentMethodFields";
import { computed, onMounted, toRef } from "vue";
import { PaymentFormContext, usePaymentStore } from "@/store/payment";
import { onBeforeUnmount } from "vue";

interface Props {
    config: FieldsEbanx;
    form: PaymentFormContext;
}

const props = defineProps<Props>();
const form = toRef(() => props.form);
const paymentStore = usePaymentStore();

const fields = computed(() => {
    return props.config.fields.reduce(
        (acc: Record<string, Field>, field: Field) => {
            acc[field.name] = field;
            return acc;
        },
        {},
    );
});

onMounted(() => {
    paymentStore.setFieldsReady(true);
});

onBeforeUnmount(() => {
    paymentStore.setFieldsReady(false);
});
</script>
