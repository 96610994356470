import { useScriptTag } from "@vueuse/core";
import { fingerprint } from "@/services/nsure";

import * as Sentry from "@sentry/browser";

interface NsureSdkConfig {
    basketId: string;
    endpointSdk: string;
    appId: string;
    storeId: number;
    isStandalone: boolean;
}

export const useNsure = async (config: NsureSdkConfig) => {
    // NOTE: nSureAsyncInit is only executed if nSure loads successfully!
    // nSure can fail to load if the user has an overzealous adblock / JS blocker / DNS-level privacy blocker (PiHole etc)
    // This edge-case can be recreated by installing the "NoScript" browser extension, or by blocking the nSure script in the network tab of your browser devtools
    window.nSureAsyncInit = (deviceId) => {
        // Initialize nSure SDK
        window.nSureSDK.init({
            appId: config.appId,
            storeInfo: {
                id: `${config.storeId}`,
                type: config.isStandalone ? "directCheckout" : "storeFront",
            },
        });

        // Send fingerprint to nSure
        fingerprint(config.basketId, deviceId);
    };

    // nSure SDK is loaded via a script tag
    const { load } = useScriptTag(config.endpointSdk, undefined, { manual: true });

    try {
        await load();
    }
    catch (e) {
        console.warn("Failed to load nSure");
        Sentry.captureMessage("Failed to load nSure script - fingerprint not available");
        Sentry.captureException(e);
    }
};
