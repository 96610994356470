<template>
    <div class="payment-methods">
        <v-toolbar class="bg-transparent" density="compact">
            <v-btn
                :to="{ name: 'payment' }"
                class="ma-0 mb-3 bg-secondary"
                prepend-icon="mdi-chevron-left"
            >
                Back
            </v-btn>
        </v-toolbar>

        <label for="search" class="mt-5">Search</label>
        <v-text-field name="search" id="search" v-model="search"></v-text-field>

        <PaymentMethodSelector
            class="w-100"
            :items="paymentMethodStore.searchByName(search)"
            v-model="paymentMethodStore.selectedPaymentMethod"
            hide-show-more
            itemHeight="80px"
            :cols="smAndUp ? 4 : 2"
            @change="() => $router.push({ name: 'payment' })"
            :loading="paymentMethodStore.items.isLoading"
            :skeleton-number="32"
            :disabled="paymentMethodStore.items.isLoading"
        />
    </div>
</template>

<script setup lang="ts">
import { usePaymentMethodStore } from "@/store/payment-method";
import PaymentMethodSelector from "@/components/payment/PaymentMethodSelector.vue";
import { useDisplay } from "vuetify";
import { ref } from "vue";
import { onMounted } from "vue";

const paymentMethodStore = usePaymentMethodStore();
const { smAndUp } = useDisplay();

const search = ref("");

onMounted(() => {
    paymentMethodStore.fetchPaymentMethods();
});
</script>

<style lang="scss" scoped>
.payment-methods {
    padding-bottom: 2rem;
}
</style>
