import { redirectInNewTabWithSpinner } from "@/helpers/redirect";
import { watchForWindowClose } from "@/helpers/window-closed";
import { useRouter } from "vue-router";

export const useRedirect = () => {
    const current = new URL(window.location.href);
    const router = useRouter();

    return {
        /**
         * Redirects to the given url in a new tab. Resolves when the tab is closed.
         */
        redirectInNewTab: (url: string): Promise<void> => {
            return new Promise((resolve) => {
                const page = redirectInNewTabWithSpinner(url);
                watchForWindowClose(page, resolve);
            });
        },
        /**
         * Redirects to the given url. If the url is external then the page will be replaced.
         */
        redirect: (urlString: string) => {
            const url = new URL(urlString);
            const isExternal = url.origin !== current.origin;

            if (isExternal) {
                window.location.replace(url);
            } else {
                router.push(url.pathname);
            }
        },
    };
};
