<template>
    <div
        v-if="discordLoginUrl || isLoggedIn"
        class="login-with-discord border pa-4"
    >
        <v-icon size="3rem" :color="theme.current.value.dark ? '#ffffff' : ''">
            custom:discord
        </v-icon>

        <div class="login-with-discord__text">
            <h3>{{ t("discord.login_title") }}</h3>
            <p>
                {{
                    t("discord.login_description", {
                        account: appStore.config?.name,
                    })
                }}
            </p>
        </div>

        <v-btn v-if="isLoggedIn" id="discord-login" color="secondary" disabled>
            {{ t("discord.logged_in") }}
        </v-btn>

        <v-btn
            v-else-if="discordLoginUrl"
            id="discord-login"
            color="primary"
            @click="onLoginClick"
        >
            {{ t("discord.login_with_discord") }}
        </v-btn>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRedirect } from "@/composables/useRedirect";
import { useAppStore } from "@/store/app";
import { useTheme } from "vuetify/lib/framework.mjs";

interface Props {
    discordLoginUrl: string | null;
    isLoggedIn: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["discord-login-complete"]);

const { t } = useI18n();
const { redirectInNewTab } = useRedirect();
const appStore = useAppStore();
const theme = useTheme();

const onLoginClick = async () => {
    if (!props.discordLoginUrl) {
        throw new Error("Discord login url is not set");
    }

    await redirectInNewTab(props.discordLoginUrl + "?completeAction=close");

    emit("discord-login-complete");
};
</script>

<style setup lang="scss">
.login-with-discord {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
</style>
