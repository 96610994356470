<template>
    <div class="ma-n4 pb-5">
        <v-container fluid class="pb-0">
            <v-row v-if="!isFree">
                <v-col>
                    <PaymentMethodSelector
                        :items="paymentMethodStore.slicedPaymentMethods"
                        v-model="paymentMethodStore.selectedPaymentMethod"
                        :cols="smAndUp ? 4 : 2"
                        :loading="paymentMethodStore.items.isLoading"
                        :skeleton-number="4"
                        :disabled="paymentMethodStore.isPaymentMethodLoading"
                    />

                    <v-skeleton-loader
                        type="text"
                        :loading="paymentMethodStore.items.isLoading"
                        class="mx-auto"
                        width="210px"
                    >
                        <div class="d-flex justify-center w-100 mb-4">
                            <!-- Route depends on if we are in an iframe or not -->
                            <v-btn
                                v-if="paymentMethodStore.items.state.length > 4"
                                to="select-payment-method"
                                class="text-gray-600 show-more-payments"
                            >
                                {{ t("payment_method.show_more") }}
                            </v-btn>
                            <div v-else class="mb-4">
                                <!-- Spacer to boost margin when the "show more" text isn't shown -->
                            </div>
                        </div>
                    </v-skeleton-loader>
                </v-col>
            </v-row>

            <div
                class="payment-details"
                :style="{
                    overflow: showSkeleton ? 'hidden' : 'visible',
                    maxHeight: showSkeleton ? '450px' : undefined,
                }"
            >
                <SkeletonLoaderForm
                    v-if="showSkeleton"
                    class="payment-details-skeleton"
                    :quantity="6"
                    :loading="true"
                >
                </SkeletonLoaderForm>

                <template
                    v-if="
                        !isDataLoading &&
                        paymentMethodStore.selectedPaymentMethodDetails
                    "
                >
                    <form
                        :style="{
                            visibility: showSkeleton ? 'hidden' : 'visible',
                        }"
                        @submit.prevent="onSubmit"
                    >
                        <Fields
                            :payment-method-details="
                                paymentMethodStore.selectedPaymentMethodDetails
                            "
                        />

                        <v-row>
                            <v-col ref="submitButtonContainer">
                                <v-btn
                                    v-if="shouldShowSubmitButton"
                                    color="primary"
                                    type="submit"
                                    width="100%"
                                    :loading="isSubmitting"
                                    :disabled="appStore.isApiRunning"
                                    data-testid="payment-submit"
                                >
                                    {{ submitButtonLabel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </form>
                </template>
            </div>
        </v-container>
    </div>
</template>

<script setup lang="ts">
import Fields from "@/components/payment/PaymentFields.vue";
import PaymentMethodSelector from "@/components/payment/PaymentMethodSelector.vue";
import SkeletonLoaderForm from "@/components/skeleton/SkeletonLoaderForm.vue";
import { useZoid } from "@/composables/useZoid";
import router from "@/plugins/router";
import { isAxiosError, throwErrorIfNecessary } from "@/services/api";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import { usePaymentStore } from "@/store/payment";
import { usePaymentMethodStore } from "@/store/payment-method";
import { useMessageStore } from "@/store/message";
import { ErrorResponse } from "@/types/Api";
import { provide } from "vue";
import { Ref, computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";
import { waitForAction } from "@/helpers/wait-for-action";
import { useCurrencyFormatter } from "@/composables/useCurrencyFormatter";

const { t, locale } = useI18n();
const { smAndUp } = useDisplay();
const paymentMethodStore = usePaymentMethodStore();
const paymentStore = usePaymentStore();
const appStore = useAppStore();
const basketStore = useBasketStore();
const messageStore = useMessageStore();
const zoid = useZoid();

const isSubmitting: Ref<boolean> = ref(false);

const isFree = computed(() => {
    return basketStore.basketData?.basket.price.balance === 0;
});

const { formatCurrency } = useCurrencyFormatter(
    locale,
    basketStore.currencyCode,
);

const amount = computed(() => {
    if (
        basketStore.showConversion &&
        basketStore.totalWithConversion &&
        paymentMethodStore.selectedPaymentMethodDetails?.currency
    ) {
        return formatCurrency(
            basketStore.totalWithConversion,
            paymentMethodStore.selectedPaymentMethodDetails.currency,
        );
    }

    return formatCurrency(basketStore.balance);
});

const isDataLoading = computed(() => {
    return (
        paymentMethodStore.isPaymentMethodLoading ||
        paymentMethodStore.items.isLoading ||
        appStore.isConfigLoading ||
        basketStore.shouldShowLoader
    );
});

const showSkeleton = computed(() => {
    // Show the skeleton if we are loading the payment methods or the fields are not ready yetp
    return isDataLoading.value === true || paymentStore.isFieldsReady === false;
});

const submitButtonContainer = ref(null);
provide("submitButtonContainer", submitButtonContainer);

const onSubmit = (...args: any[]) => {
    const form = paymentStore.getActiveForm();

    if (!form) {
        throw new Error("No active form found");
    }

    form.handleSubmit(async (data) => {
        isSubmitting.value = true;

        // Make the payment
        try {
            if (basketStore.isPaymentMethodUpdate) {
                await paymentStore.updatePaymentMethod(data);
            } else {
                await paymentStore.makePayment({
                    ...data,
                    completeAction: zoid.isChild() ? "close" : "modern",
                });
            }
        } catch (error) {
            if (isAxiosError<ErrorResponse>(error)) {
                const message =
                    error.response?.data.message ?? t("error.unexpected");
                messageStore.addMessage(message, { type: "error" });
            }

            throwErrorIfNecessary(error, "Error making payment");
        } finally {
            isSubmitting.value = false;
        }
    })(...args);
};

// Provide the submit handler to the fields
provide("submitHandler", onSubmit);

onMounted(async () => {
    // If the basket hasn't loaded yet, wait for it to load
    if (!basketStore.isReady) {
        await waitForAction(basketStore, "fetchBasket");
    }

    if (basketStore.address?.country === "XX") {
        router.push({ name: "country-selection" });
    } else {
        await paymentMethodStore.fetchPaymentMethods();

        if (isFree.value) {
            paymentMethodStore.setSelectedPaymentMethodIndex(0);
        }
    }
});

// TODO: in future I think we should rethink this logic as it is getting a bit complex
const shouldShowSubmitButton = computed(() => {
    // If the checkout is in test mode, always display standard submit button
    if (appStore.config?.testMode) {
        return true;
    }

    // For cashapp we want to show the button associated with cashapp
    if (paymentMethodStore.selectedPaymentMethod?.ident === "cashapp") {
        return false;
    }

    const hasLocalPaymentMethodSelected = ["googlepay", "applepay"].includes(
        paymentMethodStore.selectedPaymentMethod?.ident ?? "",
    );

    // If they have not selected a local payment method show the regular submit button
    if (!hasLocalPaymentMethodSelected) {
        return true;
    }

    // If they are not within zoid show the button associated with apple or google pay
    if (!zoid.isChild()) {
        return false;
    }

    // If they are within zoid they can either be in a popup or embedded
    // For embedded we want to show the regular button
    if (zoid.xprops.isEmbedded) {
        return true;
    } else {
        return false;
    }
});

const submitButtonLabel = computed(() => {
    if (isFree.value) {
        return t("payment.form.complete_order");
    }

    const { ident } = paymentMethodStore.selectedPaymentMethod ?? {};

    if (
        !appStore.config?.testMode &&
        (ident === "googlepay" || ident === "applepay")
    ) {
        return t("buttons.continue");
    }

    if (basketStore.isPaymentMethodUpdate) {
        return t("buttons.update_payment_method");
    }

    return t("payment.form.pay_with", {
        amount: amount.value,
        method: paymentMethodStore.selectedPaymentMethod?.name,
    });
});
</script>

<style scoped lang="scss">
@use "@/styles/settings.scss";
.terms-checkbox :deep(label) {
    font-size: 11px;
}

.show-more-payments {
    margin-top: 7px;
    text-decoration: underline;
}

// Customise the skeleton loader for the form
.payment-details-skeleton {
    :deep(.v-skeleton-loader__field) {
        padding: 6px;
        &:nth-of-type(2) {
            max-width: 50%;
        }

        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(3) {
            max-width: 50%;
        }

        @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
            &:nth-of-type(1),
            &:nth-of-type(4),
            &:nth-of-type(5) {
                max-width: 50%;
            }

            &:nth-of-type(2) {
                max-width: 25%;
            }

            &:nth-of-type(3) {
                max-width: 25%;
            }
        }
    }
}
</style>
