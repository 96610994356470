import { Adyen, createAdyenCheckout } from "@/services/adyen";
import { useAppStore } from "@/store/app";
import { tryOnBeforeMount } from "@vueuse/core";
import { shallowRef } from "vue";

const checkout = shallowRef<Adyen | undefined>();

export interface UseAdyenOptions {
    immediate?: boolean;
    manual?: boolean;
    onCheckoutCreate?: (checkout: Adyen) => void;
}

export const useAdyen = (
    options: UseAdyenOptions = {},
    otherOptions: any = {},
) => {
    const { immediate = true, manual = false, onCheckoutCreate } = options;

    const appStore = useAppStore();

    const load = async () => {
        // No point in creating a new checkout if we already have one
        if (!checkout.value) {
            checkout.value = await createAdyenCheckout({
                locale: appStore.locale,
                environment: import.meta.env?.VITE_ADYEN_ENV
                    ? import.meta.env.VITE_ADYEN_ENV
                    : appStore.config?.services.adyen.environment,
                clientKey: import.meta.env?.VITE_ADYEN_CLIENT_KEY
                    ? import.meta.env.VITE_ADYEN_CLIENT_KEY
                    : appStore.config?.services.adyen.clientKey,
                originKey: import.meta.env?.VITE_ADYEN_ORIGIN_KEY
                    ? import.meta.env.VITE_ADYEN_ORIGIN_KEY
                    : appStore.config?.services.adyen.originKey,
                analytics: {
                    enabled: false, // Set to false to not send analytics data to Adyen.
                },
                ...otherOptions,
            });
        }

        if (onCheckoutCreate) {
            onCheckoutCreate(checkout.value);
        }

        return checkout.value;
    };

    if (immediate && !manual) {
        tryOnBeforeMount(load);
    }

    return {
        checkout,
        load,
    };
};
