<template>
    <v-card elevation="0">
        <template v-slot:title>
            {{ upsellItem.name }}
        </template>
        <v-card-item v-for="option in upsellItem.options" :key="option.id">
            <label v-if="option.type !== 'discord_id'" :for="option.name">
                {{ option.description }}
            </label>

            <DiscordOption
                v-if="option.type === 'discord_id'"
                :option="option"
                v-model="option.discordSignature"
            ></DiscordOption>

            <v-select
                v-if="option.type === 'dropdown'"
                :id="option.name"
                :name="option.name"
                v-model="option.value"
                :items="option.options"
                item-title="label"
                item-value="value"
                variant="outlined"
            >
                <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :subtitle="`+${
                            appStore.config?.currency.symbol ?? '$'
                        }${item.raw.price}`"
                    ></v-list-item>
                </template>
            </v-select>

            <v-text-field
                v-else-if="option.type === 'text'"
                :id="option.name"
                :name="option.name"
                v-model="option.value"
                :items="option.options"
            ></v-text-field>

            <v-text-field
                v-else-if="option.type === 'email'"
                :id="option.name"
                :name="option.name"
                v-model="option.value"
                :items="option.options"
            >
            </v-text-field>
        </v-card-item>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                @click="emit('submit', upsellItem)"
                color="primary"
                variant="flat"
                :loading="loading"
            >
                {{ t("buttons.confirm") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
// Types
import UpsellItem from "@/types/UpsellItem";

// Composables
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app";

// Components
import DiscordOption from "@/components/basket/DiscordOption.vue";

interface Props {
    upsellItem: UpsellItem;
    loading: boolean;
}

defineProps<Props>();
const emit = defineEmits<{
    (e: "submit", upsellItem: UpsellItem): void;
}>();

const { t } = useI18n();
const appStore = useAppStore();
</script>
