import popupManager from "@/helpers/popup-manager";
import { MaybeElement, unrefElement } from "@vueuse/core";
import { Ref, nextTick, ref } from "vue";

const WINDOW_LOAD_TIMEOUT = 500;

interface WindowPortalOptions {
    target?: "_blank" | "_self";
    checkForCloseInterval?: number;
}

const defaultOptions: WindowPortalOptions = {
    target: "_blank",
    checkForCloseInterval: 500,
};

export const useWindowPortal = (
    target: Ref<MaybeElement>,
    emit: (event: any, ...args: any[]) => void,
    options: WindowPortalOptions = defaultOptions,
) => {
    const _options = { ...defaultOptions, ...options };

    const windowRef: Ref<Window | null> = ref(null);

    const watchForClose = (page: Window): void => {
        const interval = setInterval(() => {
            if (page.closed) {
                clearInterval(interval);
                emit("window:close");
            }
        }, _options.checkForCloseInterval);
    };

    const appendElementOnLoad = (
        // The element to append
        element: HTMLElement | SVGElement,
        // The window to append the element to
        win: Window,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            const appendElement = () => {
                try {
                    win.document.body.append(element);
                    resolve();
                } catch (error) {
                    reject();
                }
            };

            // Wait for the window to load
            win.addEventListener("load", appendElement);
            // Fallback in case the load event is not triggered
            setTimeout(appendElement, WINDOW_LOAD_TIMEOUT);
        });
    };

    const open = async (): Promise<void> => {
        await nextTick();

        windowRef.value = popupManager.createOrReusePopup();

        if (!windowRef.value) {
            throw new Error("Failed to open window");
        }

        const element = unrefElement(target);

        if (!element) {
            throw new Error("Portal child is not defined");
        }

        try {
            await appendElementOnLoad(element, windowRef.value);
        } catch (error) {
            // Close the popup if the element could not be appended so the user can try again
            popupManager.closePopup();
        }

        emit("window:open");

        watchForClose(windowRef.value);
    };

    const close = () => {
        if (windowRef.value) {
            emit("window:close");
            windowRef.value.close();
            windowRef.value = null;
        }
    };

    return {
        windowRef,
        open,
        close,
    };
};
