<template>
    <v-sheet class="h-100 d-flex justify-center align-center flex-column">
        <v-icon :color="iconColor" :size="40">{{ icon }}</v-icon>
        <h1 class="mb-5 mt-4">{{ title }}</h1>
        <p class="mb-5">{{ description }}</p>
        <slot>
            <v-btn v-if="zoid.isChild()" @click="navigateBack" color="primary">
                {{ t("buttons.close") }}
            </v-btn>
        </slot>
    </v-sheet>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useErrorPage } from "@/composables/useErrorPage";
import { useZoid } from "@/composables/useZoid";

withDefaults(
    defineProps<{
        title: string;
        description: string;
        icon?: string;
        iconColor?: string;
    }>(),
    {
        icon: "mdi-alert-circle",
        iconColor: "red",
    },
);

const { navigateBack } = useErrorPage();
const { t } = useI18n();
const zoid = useZoid();
</script>
