<template>
    <div></div>
</template>

<script lang="ts" setup>
import { usePaymentStore } from "@/store/payment";
import { onBeforeUnmount, onMounted } from "vue";

// Note: This is a dummy component to avoid the "No matching components found" error

const paymentStore = usePaymentStore();

onMounted(() => {
    paymentStore.setFieldsReady(true);
});

onBeforeUnmount(() => {
    paymentStore.setFieldsReady(false);
});
</script>
