<template>
    <div class="container">
        <div class="payment-waiting">
            <Loader class="mb-8" />

            <div class="text-center mb-11">
                {{ t("payment.payment_waiting") }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Loader from "@/components/app/Loader.vue";
import { useI18n } from "vue-i18n";
import { onMounted } from "vue";
import { useBasketStore } from "@/store/basket";
import { useRouter } from "vue-router";

const { t } = useI18n();
const basketStore = useBasketStore();
const router = useRouter();

onMounted(() => {
    if (!basketStore.isPaymentInProgress) {
        router.push({ name: "payment" });
    }
});
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
}
.payment-waiting {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 475px;
}
</style>
