<template>
    <DialogOrContainer />
</template>

<script setup lang="ts">
import { usePaymentStore } from "@/store/payment";
import { SetupResponseEbanxVoucherData } from "@/types/SetupResponse";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useRedirect } from "@/composables/useRedirect";
import { h, watch, ref, onMounted } from "vue";
import { useZoid } from "@/composables/useZoid";
import { VBtn, VDialog } from "vuetify/lib/components/index.mjs";

interface Props {
    data: SetupResponseEbanxVoucherData;
}

const props = defineProps<Props>();

const { t } = useI18n();
const paymentStore = usePaymentStore();
const router = useRouter();
const { redirect } = useRedirect();
const zoid = useZoid();

const showDialog = ref(false);

const back = () => {
    paymentStore.setupResponse = null;

    router.push({
        name: "payment",
    });
};

const next = () => {
    redirect(props.data.next);
};

const DialogOrContainer = () => {
    // Generate the component content
    const content = createContent();

    // If not in the popup show the content within a dialog
    if (!zoid.isChild()) {
        return h(
            VDialog,
            {
                modelValue: showDialog.value,
                "onUpdate:modelValue": (value: boolean) => {
                    showDialog.value = value;
                },
                minHeight: 700,
                width: 700,
            },
            [content],
        );
    } else {
        // If within the popup show the container
        return content;
    }
};

// Create the content for the dialog or container
const createContent = () =>
    h("div", { class: "bg-white" }, [
        h("iframe", {
            class: "w-100 border-0 voucher-iframe",
            src: props.data.url,
            style: {
                height: "75vh",
            },
        }),
        h(
            "div",
            {
                class: "d-flex justify-space-between mt-3 pa-3",
            },
            [
                h(
                    VBtn,
                    {
                        color: "secondary",
                        onClick: back,
                    },
                    [t("buttons.cancel_transfer")],
                ),
                h(
                    VBtn,
                    {
                        color: "primary",
                        onClick: next,
                    },
                    [t("buttons.confirm_sent_payment")],
                ),
            ],
        ),
    ]);

onMounted(() => {
    if (!zoid.isChild()) {
        showDialog.value = true;
    }
});

watch(showDialog, () => {
    if (!showDialog.value) {
        back();
    }
});
</script>
