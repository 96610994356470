<template>
    <!-- If there are multiple recurring items display those -->
    <div
        class="subscriptions mb-6"
        :class="theme.current.value.dark ? 'bg-fields' : 'bg-gray-900'"
    >
        <div
            class="w-100 font-weight-bold"
            :class="theme.current.value.dark ? '' : 'text-gray-400'"
        >
            {{ t("basket.subscription") }}
        </div>
        <div
            v-for="item in recurringItems"
            :key="item.id"
            class="w-100 d-flex justify-space-between"
        >
            <span
                :class="
                    theme.current.value.dark ? 'text-gray-700' : 'text-gray-400'
                "
            >
                {{
                    t("basket.recurring_period", {
                        recurringPeriod: item.recurring_period,
                    })
                }}
            </span>
            <span>
                {{ formatCurrency(item.recurring_price * item.quantity) }}
                {{ taxMode === "exclusive" ? `(+${taxName})` : "" }}
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";
import { useCurrencyFormatter } from "@/composables/useCurrencyFormatter";

interface BasketSubscriptionSummaryProps {
    currency: string;
    recurringItems: any[];
    taxMode?: string;
    taxName?: string;
}

const props = defineProps<BasketSubscriptionSummaryProps>();

const { t, locale } = useI18n();

const theme = useTheme();
const { formatCurrency } = useCurrencyFormatter(locale, props.currency);
</script>

<style lang="scss" scoped></style>
