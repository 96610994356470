/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Polyfills
import "array.prototype.at/auto";

// 3rd party
import zoid from "@/composables/useZoid";
import "@/helpers/popup-manager";

// Components
import App from "./App.vue";

// Composables
import { createApp } from "vue";

// Plugins
import { registerPlugins } from "@/plugins";
const app = createApp(App);

app.provide("zoid", zoid);

registerPlugins(app);

app.mount("#app");

console.log("🚀");
