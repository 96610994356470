<template>
    <span v-if="error">
        {{ error }}
    </span>
</template>

<script setup lang="ts">
import {
    onMounted,
    onBeforeUnmount,
    unref,
    inject,
    Ref,
    ComponentPublicInstance,
    watch,
    toRef,
    ref,
} from "vue";
import { PaymentFormContext, usePaymentStore } from "@/store/payment";
import { useAdyen } from "@/composables/useAdyen";
import { type Adyen } from "@/services/adyen";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import UIElement from "@adyen/adyen-web/dist/types/components/UIElement";
import { type FieldsCashapp } from "@/types/PaymentMethodFields";
import { PaymentAmount } from "@adyen/adyen-web/dist/types/types";
import { getErrorMessage } from "@/helpers/error";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";

interface Props {
    config: FieldsCashapp;
    form: PaymentFormContext;
    fieldsUpdating: Set<string>;
}

const props = defineProps<Props>();
const form = unref(props.form);

const appStore = useAppStore();
const basketStore = useBasketStore();
const paymentStore = usePaymentStore();
const theme = useTheme();

const submitButtonContainer = inject<Ref<ComponentPublicInstance>>(
    "submitButtonContainer",
);

const submitHandler = inject<Function>("submitHandler");
const fieldsUpdating = toRef(props, "fieldsUpdating");

let component: UIElement | null = null;

const error = ref<string | null>(null);
const { t } = useI18n();

function calcAmount() {
    if (appStore.config === null) {
        throw new Error("No app config found");
    }

    if (basketStore.price === null) {
        throw new Error("Basket price found");
    }

    return {
        currency: "USD", // Cashapp only supports USD
        value: Math.round(basketStore.totalWithConversion * 100),
    };
}

async function createCashApp(checkout: Adyen) {
    const { configuration } = props.config.fields.paymentMethods.find(
        (m: any) => m.type === "cashapp",
    );

    let amount: PaymentAmount;

    try {
        amount = calcAmount();
    } catch (err) {
        // If we can't calculate the amount, we can't continue
        error.value = import.meta.env.DEV
            ? getErrorMessage(err)
            : t("error.unexpected");
        return;
    }

    component = checkout.create("cashapp", {
        environment: import.meta.env.DEV ? "TEST" : "LIVE",
        amount,
        configuration,
        countryCode: basketStore.address?.country == "US" ? "US" : "GB",
        button: {
            theme: theme.current.value.dark ? "light" : "dark",
        },
        async onClick(actions: { resolve: Function; reject: Function }) {
            // Don't submit the form if the user is updating their post code
            // This is because the amount may change if the user is updating their address
            if (fieldsUpdating.value.has("postalCode")) {
                return;
            }

            const result = await form.validate();

            if (result?.valid) {
                actions.resolve();
            }
        },
        onChange(state: any) {
            form.setValues(state.data, false);

            if (state.isValid && submitHandler) {
                submitHandler();
            }
        },
    });

    if (!submitButtonContainer?.value || !submitButtonContainer.value.$el) {
        throw new Error("No submit button container found");
    }

    component.mount(submitButtonContainer.value.$el);
}

const { load, checkout } = useAdyen({
    manual: true, // We don't want to load Adyen until we are ready
    onCheckoutCreate: createCashApp,
});

watch(
    () => basketStore.totalWithConversion,
    () => {
        // Update the amount if the total changes by re-mounting the component
        if (component && checkout.value) {
            component.unmount();

            // Re-create the component
            createCashApp(checkout.value);
        }
    },
);

onMounted(async () => {
    await load();

    setTimeout(() => {
        paymentStore.setFieldsReady(true);
    }, 400);
});

onBeforeUnmount(() => {
    paymentStore.setFieldsReady(false);

    if (component) {
        component.unmount();
    }
});
</script>
