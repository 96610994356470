import { MaybeRefOrGetter, computed, toValue } from "vue";

const normalizeLocale = (
    locale: MaybeRefOrGetter<string | undefined>,
): string => {
    let localeValue = toValue(locale) || "en-US";
    localeValue = localeValue.replace("_", "-");
    return localeValue;
};

const normalizeCurrency = (
    currency: MaybeRefOrGetter<string | undefined>,
): string => {
    return toValue(currency) || "USD";
};

const defaultOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};

export function useCurrencyFormatter(
    locale: MaybeRefOrGetter<string | undefined>,
    currency: MaybeRefOrGetter<string | undefined>,
    options: MaybeRefOrGetter<Intl.NumberFormatOptions> = defaultOptions,
) {
    /**
     * Returns a new formatter instance.
     */
    const formatter = computed(() => {
        const localeValue = normalizeLocale(locale);
        const currencyValue = normalizeCurrency(currency);
        const formatterOptions = {
            ...defaultOptions,
            ...toValue(options),
        };

        return new Intl.NumberFormat(localeValue, {
            ...formatterOptions,
            style: "currency",
            currency: currencyValue,
        });
    });

    /**
     * Returns a new formatter with the overrides (if provided).
     */
    const formatterWithOverrides = (
        overrideCurrency: MaybeRefOrGetter<string | undefined> | undefined,
        overrideLocale: MaybeRefOrGetter<string | undefined> | undefined,
    ) => {
        if (overrideCurrency || overrideLocale) {
            const localeValue =
                normalizeLocale(overrideLocale) || normalizeLocale(locale);
            const currencyValue =
                normalizeCurrency(overrideCurrency) ||
                normalizeCurrency(currency);
            const formatterOptions = {
                ...defaultOptions,
                ...toValue(options),
                style: "currency",
                currency: currencyValue,
            };

            return new Intl.NumberFormat(localeValue, {
                ...formatterOptions,
                style: "currency",
                currency: currencyValue,
            });
        } else {
            return formatter.value;
        }
    };

    /**
     * Formats a number into a currency string.
     */
    const formatCurrency = (
        amount: number,
        overrideCurrency?: MaybeRefOrGetter<string | undefined>,
        overrideLocale?: MaybeRefOrGetter<string | undefined>,
    ) => {
        const formatter = formatterWithOverrides(
            overrideCurrency,
            overrideLocale,
        );

        const { currency } = formatter.resolvedOptions();

        let formattedAmount = formatter.format(amount);

        // If the currency is USD, we need to append the currency symbol manually.
        // But note that the formatter for some languages (such as Italian) already does this.
        if (currency === "USD" && !formattedAmount.endsWith("USD"))
            formattedAmount += " USD";

        return formattedAmount;
    };

    return {
        formatCurrency,
    };
}
