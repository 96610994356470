<template>
    <div class="d-flex flex-column h-100 align-center justify-center">
        <Loader class="mb-8" />

        <div class="text-center mb-11">
            {{ t("ui.loading") }}
        </div>
    </div>

    <PostForm />
</template>

<script setup lang="ts">
import { SetupResponsePostData } from "@/types/SetupResponse";
import Loader from "@/components/app/Loader.vue";
import { useI18n } from "vue-i18n";
import { Ref, ref, onMounted } from "vue";
import { useZoid } from "@/composables/useZoid";
import WindowPortal from "@/components/helpers/WindowPortal.vue";
import { usePaymentStore } from "@/store/payment";
import { h } from "vue";
import { useRouter } from "vue-router";
import { useMessageStore } from "@/store/message";
import { useBasketStore } from "@/store/basket";

interface Props {
    data: SetupResponsePostData;
}

const props = defineProps<Props>();

const { t } = useI18n();
const zoid = useZoid();
const router = useRouter();
const messageStore = useMessageStore();

const form = ref<HTMLFormElement | null>(null);
const showPortal = ref(false);
const portal: Ref<InstanceType<typeof WindowPortal> | null> = ref(null);

const paymentStore = usePaymentStore();
const basketStore = useBasketStore();

onMounted(async () => {
    // If in the popup redirect the current user to the payment page
    if (zoid.isChild()) {
        showPortal.value = true;
    } else if (form.value) {
        form.value.submit();
    }
});

const onWindowPortalOpen = () => {
    if (portal.value) {
        const win = portal.value.windowRef;

        // Submit the form
        const portalForm = win?.document.querySelector<HTMLFormElement>(
            "form[name=setup_response_post]",
        );

        if (!portalForm) {
            console.error("Form not found in the opened window");
            router.push({
                name: "basket",
                params: { basketId: basketStore.basketId },
            });
            messageStore.addMessage(t("error.unexpected"), { type: "error" });
            return;
        }

        portalForm.submit();
    }
};

const onWindowPortalClose = async () => {
    await paymentStore.completePayment();
};

const createForm = () => {
    const fields = Object.entries(props.data.fields).map(([name, value]) => {
        return h("input", {
            key: name,
            name,
            value,
            type: "hidden",
        });
    });

    return h(
        "form",
        {
            ref: form,
            name: "setup_response_post",
            method: "post",
            action: props.data.url,
        },
        [fields],
    );
};

const PostForm = () => {
    const form = createForm();

    if (zoid.isChild()) {
        return h(
            WindowPortal,
            {
                modelValue: showPortal.value,
                ref: portal,
                "onWindow:close": onWindowPortalClose,
                "onWindow:open": onWindowPortalOpen,
            },
            () => [form],
        );
    } else {
        return form;
    }
};
</script>
