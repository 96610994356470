import * as Sentry from "@sentry/vue";
import { captureConsoleIntegration } from "@sentry/integrations";
import { Plugin } from "vue";
import { Router } from "vue-router";

const sentryPlugin: Plugin = {
    install(app, options: { router: Router }) {
        // Do not initialize Sentry in development mode
        if (!import.meta.env.DEV) {
            Sentry.init({
                app,
                dsn: "https://e212814b76ec1aa5d145b1fd72c0ef49@o4507015952793600.ingest.us.sentry.io/4507015954038784",
                integrations: [
                    captureConsoleIntegration({
                        levels: ["error"],
                    }),
                    Sentry.browserTracingIntegration({
                        router: options.router,
                    }),
                    Sentry.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: false,
                    }),
                ],

                // Performance Monitoring
                tracesSampleRate: 0, //  Capture 0% of the transactions
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    "localhost",
                    /^https:\/\/pay.tebex\.io\//,
                ],
                // Session Replay
                replaysSessionSampleRate: 0, // This sets the sample rate at 5%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
                environment: import.meta.env.MODE,

                // This option is required for capturing headers and cookies.
                sendDefaultPii: true,

                ignoreErrors: [
                    // Random plugins/extensions
                    "top.GLOBALS",
                    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                    "originalCreateNotification",
                    "canvas.contentDocument",
                    "MyApp_RemoveAllHighlights",
                    "http://tt.epicplay.com",
                    "Can't find variable: ZiteReader",
                    "jigsaw is not defined",
                    "ComboSearch is not defined",
                    "http://loading.retry.widdit.com/",
                    "atomicFindClose",
                    // Facebook borked
                    "fb_xd_fragment",
                    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
                    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
                    "bmi_SafeAddOnload",
                    "EBCallBackMessageReceived",
                    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                    "conduitPage",
                    // Generic error code from errors outside the security sandbox
                    // You can delete this if using raven.js > 1.0, which ignores these automatically.
                    "Script error.",
                    // Avast extension error
                    "_avast_submit",
                ],
                denyUrls: [
                    // Google Adsense
                    /pagead\/js/i,
                    // Facebook flakiness
                    /graph\.facebook\.com/i,
                    // Facebook blocked
                    /connect\.facebook\.net\/en_US\/all\.js/i,
                    // Woopra flakiness
                    /eatdifferent\.com\.woopra-ns\.com/i,
                    /static\.woopra\.com\/js\/woopra\.js/i,
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    /^chrome-extension:\/\//i,
                    // Other plugins
                    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                    /webappstoolbarba\.texthelp\.com\//i,
                    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                ],
            });
        }
    },
};

export default sentryPlugin;
