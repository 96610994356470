<template>
    <ErrorPage
        :title="t('basket.empty.title')"
        :description="t('basket.empty.description')"
    />
</template>

<script setup lang="ts">
import ErrorPage from "@/pages/error/ErrorPage.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
