import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
import type { I18n, I18nOptions, Locale, Composer, VueI18n } from "vue-i18n";
import { setLocale as setVeeValidateLocale } from "@vee-validate/i18n";
import { loadLocaleValidationMessages } from "./validation";
import { MessageSchema } from "@/vue-i18n";
import en_US from "@/lang/en_US.json";

const modules = import.meta.glob<MessageSchema>("../lang/*.json");

export const locales = new Map();

Object.entries(modules).forEach(([path, module]) => {
    const locale = path.replace(/^.*[\\/]/, "").split(".")[0];
    locales.set(locale, module as () => Promise<MessageSchema>);
});

export const AVAILABLE_LOCALES: Record<string, string> = {
    en_US: "English (US)",
    pt_BR: "Portuguese (Brazil)",
    el_GR: "Greek",
    lv_LV: "Latvian",
    it_IT: "Italian",
    uk_UA: "Ukrainian",
    ms_MY: "Malay",
    fi_FI: "Finnish",
    cs_CZ: "Czech",
    hr_HR: "Croatian",
    fr_FR: "French",
    sv_SE: "Swedish",
    tr_TR: "Turkish",
    vi_VN: "Vietnamese",
    sr_RS: "Serbian",
    de_DE: "German",
    ca_ES: "Catalan",
    ru_RU: "Russian",
    ko_KR: "Korean",
    hu_HU: "Hungarian",
    da_DK: "Danish",
    pl_PL: "Polish",
    nl_NL: "Dutch",
    no_NO: "Norwegian",
    ro_RO: "Romanian",
    es_ES: "Spanish",
    ja_JP: "Japanese",
    zh_CN: "Chinese (Simplified)",
    //zh_TW: "Chinese (Taiwan)",
};

export function isLocaleSupported(locale: string): boolean {
    return Array.from(locales.keys()).includes(locale);
}

export async function getLocaleMessages(
    locale: string,
): Promise<MessageSchema> {
    const module = locales.get(locale);

    if (!module) {
        throw new Error(`No locale module for "${locale}"`);
    }

    // load locale messages
    const messages = await module().then(getResourceMessages);

    return messages;
}

export function getLocale(i18n: I18n): string {
    return i18n.mode === "legacy"
        ? (i18n.global as unknown as VueI18n).locale
        : (i18n.global as unknown as Composer).locale.value;
}

export function setLocale(i18n: I18n, locale: Locale): void {
    if (i18n.mode === "legacy") {
        (i18n.global as unknown as VueI18n).locale = locale;
    } else {
        (i18n.global as unknown as Composer).locale.value = locale;
    }
}

export function setupI18n(options: I18nOptions = { locale: "en_US" }) {
    const i18n = createI18n(options);
    setI18nLanguage(i18n, options.locale!);
    return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: Locale): void {
    setLocale(i18n, locale);
    setVeeValidateLocale(locale);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResourceMessages = (r: any) => r.default;

export async function loadLocaleMessages(
    i18n: I18n,
    locale: Locale,
): Promise<void> {
    if (!isLocaleSupported(locale)) {
        console.warn(`Unsupported locale "${locale}" falling back to en_US`);
        return;
    }

    const messages = await getLocaleMessages(locale);

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages);

    await loadLocaleValidationMessages(locale, messages);

    return nextTick();
}

const instance = setupI18n({
    legacy: false,
    locale: "en_US",
    fallbackLocale: "en_US",
    messages: {
        en_US,
    },
});

export default instance;
export const i18n = instance.global;
