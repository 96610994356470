<template>
    <div class="d-flex flex-column">
        <h4 class="my-4">{{ t("discord.login_description") }}</h4>
        <v-btn
            id="discord-login-button"
            color="#7289da"
            @click="login"
            :disabled="isLoading || isLoggedIn"
            class="ma-4"
            prepend-icon="custom:discord"
        >
            <template v-slot:prepend>
                <v-icon class="mt-0" size="32px"></v-icon>
            </template>
            {{ loginButtonText }}
        </v-btn>
        <v-btn v-if="isLoggedIn" color="secondary ma-4" @click="login">
            {{ t("discord.switch_user") }}
        </v-btn>
    </div>
</template>

<script setup lang="ts">
// Types
import { UpsellItemOption } from "@/types/UpsellItem";

// Utils
import { computed, ref, shallowRef, type ShallowRef } from "vue";
import { useI18n } from "vue-i18n";
import { popup } from "@/helpers/popup";
import { watchForWindowClose } from "@/helpers/window-closed";
import { DiscordLoginPayload, DiscordLoginMessage } from "@/types/Discord";
import { useMessageStore } from "@/store/message";
import { useBasketStore } from "@/store/basket";

interface Props {
    option: UpsellItemOption;
}

defineProps<Props>();

const discordSignature = defineModel();

const { t } = useI18n();
const messageStore = useMessageStore();
const basketStore = useBasketStore();

const newWindow: ShallowRef<Window | null> = shallowRef(null);
const isLoading = ref(false);
const isLoggedIn = ref(false);
const loginButtonText = computed(() =>
    isLoggedIn.value
        ? t("discord.logged_in_as", { username: username.value })
        : t("discord.login_with_discord"),
);
const username = ref("");
const baseUrl = new URL(import.meta.env.VITE_BASKET_API);

function login() {
    isLoading.value = true;

    if (newWindow.value) {
        newWindow.value.focus();
        return;
    }

    const returnUrl = `${baseUrl.origin}/api/baskets/${basketStore.basketId}/discord-login/handle`;
    const url = `https://ident.tebex.io/discord/?return=${returnUrl}`;

    newWindow.value = popup(url, "_blank", {
        width: 700,
        height: 700,
        center: true,
    });

    if (!newWindow.value) {
        throw new Error("Failed to open discord login window.");
    }

    watchForWindowClose(newWindow.value, onWindowClose);

    window.addEventListener("message", onMessage);
    window.addEventListener("beforeunload", () => {
        newWindow.value?.close();
        onWindowClose();
    });
}

function isDiscordLoginMessage(
    event: MessageEvent,
): event is MessageEvent<DiscordLoginMessage> {
    return (
        event.origin === baseUrl.origin && event.data.source === "discord-login"
    );
}

function onMessage(message: MessageEvent) {
    if (isDiscordLoginMessage(message)) {
        processLoginDetails(message.data.payload);
    }
}

function processLoginDetails(payload: DiscordLoginPayload | undefined) {
    if (!payload) {
        messageStore.addMessage(t("discord.login_failed"), { type: "error" });
    } else {
        username.value = payload.discordTag;
        discordSignature.value = payload.signature;

        isLoggedIn.value = true;
    }

    isLoading.value = false;
    newWindow.value?.close();
}

function onWindowClose() {
    newWindow.value = null;
    isLoading.value = false;
}
</script>

<style scoped lang="scss">
#discord-login-button :deep(.v-btn__prepend) {
    margin-top: 0;

    svg {
        margin-top: 2px;
    }
}
</style>
