<template>
    <!-- Standard fields that apply to all methods -->
    <div class="d-flex flex-column w-100">
        <v-row dense>
            <v-col>
                <label for="email">
                    {{ t("payment.form.email_address") }}
                </label>

                <v-text-field
                    v-model="form.fields.email.model"
                    v-bind="form.fields.email.props"
                    @blur="onBlur('email')"
                    id="email"
                    name="email"
                    :placeholder="t('payment.form.email_address_placeholder')"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="6">
                <label for="name">
                    {{ t("payment.form.full_name") }}
                </label>
                <v-text-field
                    v-model="form.fields.name.model"
                    v-bind="form.fields.name.props"
                    @blur="onBlur('name')"
                    id="name"
                    name="name"
                    :placeholder="t('payment.form.full_name_placeholder')"
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <label for="postalCode">
                    {{ t("payment.form.postal_code") }}
                </label>
                <v-text-field
                    v-model="form.fields.postalCode.model"
                    v-bind="form.fields.postalCode.props"
                    @blur="onBlur('postalCode')"
                    id="postalCode"
                    name="postalCode"
                    placeholder="PO12 1AB"
                    :loading="fieldsUpdating.has('postalCode')"
                ></v-text-field>
            </v-col>
        </v-row>
    </div>

    <!-- Fields related to the payment method are rendered below -->
    <div class="w-100">
        <component
            :is="component"
            :form="form"
            :config="paymentMethodDetails.fields"
            :fieldsUpdating="fieldsUpdating"
        ></component>
    </div>

    <v-row>
        <v-col>
            <v-checkbox
                v-model="form.fields.gdprConsent.model"
                v-bind="form.fields.gdprConsent.props"
                density="compact"
                hide-details
                class="mb-3 mt-2"
            >
                <template v-slot:label>
                    {{
                        t("payment.marketing", {
                            name: appStore.config?.name ?? "",
                        })
                    }}
                </template>
            </v-checkbox>
            <v-checkbox
                v-model="form.fields.termsAccepted.model"
                v-bind="form.fields.termsAccepted.props"
                class="mb-2"
                density="compact"
            >
                <template v-slot:label>
                    <TermsAndConditionsLink />
                </template>
            </v-checkbox>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { PaymentMethodDetails } from "@/types/PaymentMethod";
import { toRef, ref, watch, computed, type Component } from "vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import { getErrorMessage } from "@/helpers/error";
import { storeToRefs } from "pinia";
import { BillingAddress } from "@/types/BillingAddress";
import TermsAndConditionsLink from "./TermsAndConditionsLink.vue";

import FieldsAdyen from "./fields/FieldsAdyen.vue";
import FieldsApplePay from "./fields/FieldsApplePay.vue";
import FieldsDynamic from "./fields/FieldsDynamic.vue";
import FieldsEbanx from "./fields/FieldsEbanx.vue";
import FieldsGooglePay from "./fields/FieldsGooglePay.vue";
import FieldsInstructions from "./fields/FieldsInstructions.vue";
import FieldsNone from "./fields/FieldsNone.vue";
import FieldsCashApp from "./fields/FieldsCashapp.vue";
import { usePaymentStore } from "@/store/payment";
import { usePaymentForm } from "@/composables/usePaymentForm";
import { usePaymentMethodStore } from "@/store/payment-method";

const FieldComponents: {
    [key: string]: Component;
} = {
    FieldsAdyen,
    FieldsApplePay,
    FieldsDynamic,
    FieldsEbanx,
    FieldsGooglePay,
    FieldsInstructions,
    FieldsNone,
    FieldsCashApp,
};

interface Props {
    paymentMethodDetails: PaymentMethodDetails;
}

const props = defineProps<Props>();

const paymentMethodDetails = toRef(() => props.paymentMethodDetails);
const { t } = useI18n();
const appStore = useAppStore();
const basketStore = useBasketStore();
const paymentStore = usePaymentStore();
const paymentMethodStore = usePaymentMethodStore();

const { address } = storeToRefs(basketStore);
let initialValuesSet = false;

// Default schema for the form
const defaultSchema = {
    name: "required",
    email: "required|email",
    postalCode: "required",
    termsAccepted: "required",
    gdprConsent: "",
};

const { form } = usePaymentForm(paymentMethodDetails, defaultSchema);
paymentStore.setActiveForm(form);

watch(
    () => address.value,
    () => {
        if (address.value !== null && !initialValuesSet) {
            const values = {
                email: address.value?.email,
                name: address.value?.name,
                postalCode: address.value?.postalCode,
                country: address.value?.country,
            };

            for (const [key, value] of Object.entries(values)) {
                form.setFieldValue(key, value, false);
            }

            initialValuesSet = true;
        }
    },
    { immediate: true },
);

const fieldsUpdating = ref(new Set<string>());

const onBlur = async (fieldName: string) => {
    const { valid } = await form.validateField(fieldName);
    const fieldValue = form.values[fieldName];
    let currentValue = address.value?.[fieldName as keyof BillingAddress];

    // Update the basket with the field value if it is valid and has changed
    if (valid && fieldValue !== currentValue) {
        fieldsUpdating.value.add(fieldName);

        try {
            await basketStore.updateBasket({ [fieldName]: fieldValue });
            currentValue = fieldValue;
        } catch (error) {
            const message = getErrorMessage(error);
            form.setFieldError(fieldName, message);
        }

        // We need to refresh the selected payment method details as the basket has changed
        try {
            console.log("Fetching selected payment method details");
            await paymentMethodStore.fetchSelectedPaymentMethodDetails(true);
        } catch (error) {
            const message = getErrorMessage(error);
            form.setFieldError(fieldName, message);
        }

        fieldsUpdating.value.delete(fieldName);
    }
};

const component = computed(() => {
    let component = paymentMethodDetails.value?.fields?.component;

    if (!component) {
        component = "FieldsNone";
    }

    return FieldComponents[component];
});
</script>
