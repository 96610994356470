import { Store } from "pinia";

export function waitForAction(store: Store, action: string) {
    let unsubscribe: () => void;

    return new Promise((resolve, reject) => {
        unsubscribe = store.$onAction(({ name, after, onError }) => {
            after((data) => {
                if (name === action) {
                    unsubscribe();
                    resolve({ name, data });
                }
            });

            onError((error) => {
                if (name === action) {
                    unsubscribe();
                    reject(error);
                }
            });
        });
    });
}
