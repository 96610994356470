import { ThemeDefinition } from "vuetify";

export const light: ThemeDefinition = {
    dark: false,
    colors: {
        background: "#FAFAFA",
        surface: "#FFFFFF",
        primary: "#41C4C3",
        secondary: "#E5E5E5",
        "surface-variant": "#FAFAFA",
        success: "#00cc45",
        warning: "#e07000",
        error: "#C12E2E",
        green: "#00CC45",
        red: "#C12E2E",
        // Custom colours
        "gray-100": "#1a1a1a",
        "gray-200": "#262626",
        "gray-300": "#333",
        "gray-400": "#4d4d4d",
        "gray-500": "#868996",
        "gray-600": "#999999",
        "gray-700": "#b2b2b2",
        "gray-800": "#ccc",
        "gray-900": "#e5e5e5",
        fields: "#FAFAFA",
        "field-border": "#CCCCCC",
    },
    variables: {
        "activated-opacity": 0.25,
    },
};
