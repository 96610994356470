export interface PopupOptions {
    width: number;
    height: number;
    left: number;
    top: number;
    center: boolean;
    popup: boolean;
    toolbar: boolean;
    location: boolean;
    directories: boolean;
    status: boolean;
    menubar: boolean;
    scrollbars: boolean;
    resizable: boolean;
    copyhistory: boolean;
}

type Target = "_self" | "_blank" | "_parent" | "_top";

const defaultOptions = { width: 500, height: 500 };

export function popup(
    url: string,
    target: Target,
    options: Partial<PopupOptions> = defaultOptions,
) {
    let _options = { ...defaultOptions, ...options };

    if (_options.center) {
        // Fixes dual-screen position
        const dualScreenLeft =
            window.screenLeft !== undefined
                ? window.screenLeft
                : window.screenX;
        const dualScreenTop =
            window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = screen.width;
        const height = screen.height;

        const systemZoom = width / window.screen.availWidth;

        const left = (width - _options.width) / 2 / systemZoom + dualScreenLeft;
        const top = (height - _options.height) / 2 / systemZoom + dualScreenTop;

        _options = {
            ..._options,
            left,
            top,
        };
    }

    delete _options.center;

    const optionsStr = Object.keys(_options)
        .map((key: string) => `${key}=${_options[key as keyof PopupOptions]}`)
        .join(",");

    return window.open(url, target, optionsStr);
}
