<template>
    <h3 id="upsellListTitle">{{ t("upsell.title") }}</h3>
    <div class="upsell__list">
        <v-list lines="one" aria-labelledby="upsellListTitle">
            <UpsellListItem
                v-for="item in items"
                :item="item"
                :key="item.uuid"
                :currency-code="currencyCode"
                :selected="isSelected(item)"
                :loading="loadingUpsells.has(item.uuid)"
                @change="onChange"
            />
        </v-list>
    </div>
</template>

<script setup lang="ts">
import UpsellItem from "@/types/UpsellItem";
import UpsellListItem from "./UpsellListItem.vue";
import { useVModel } from "@vueuse/core";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

interface Props {
    items: UpsellItem[];
    currencyCode: string;
    modelValue: UpsellItem[];
    loadingUpsells: Set<UpsellItem["uuid"]>;
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: () => [],
});

const emit = defineEmits(["add", "remove", "update:modelValue"]);
const selected = useVModel(props, "modelValue", emit);

const isSelected = (item: UpsellItem) => {
    const index = selected.value.findIndex(
        (selectedItem) => selectedItem.uuid === item.uuid,
    );

    return index > -1;
};

const onChange = (item: UpsellItem) => {
    const index = selected.value.findIndex(
        (selectedItem) => selectedItem.uuid === item.uuid,
    );

    if (index > -1) {
        emit("remove", item);
    } else {
        emit("add", item);
    }
};
</script>
