<template>
    <!-- In some rare instances a basket may not have a country - we need to get the user to select one first -->
    <div
        v-if="
            !basketStore.address?.country ||
            basketStore.address?.country === 'XX'
        "
    >
        <v-row dense>
            <v-col>
                <label for="country">
                    {{ t("payment.form.country") }}
                </label>
                <CountrySelector
                    data-testid="country-selector"
                    :placeholder="t('payment.form.country_placeholder')"
                    v-model="country"
                    v-bind="countryProps"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-btn color="primary" @click="updateCountry">
                    {{ t("buttons.continue") }}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
import CountrySelector from "@/components/inputs/CountrySelector.vue";
import { useBasketStore } from "@/store/basket";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import { useRouter } from "vue-router";

const basketStore = useBasketStore();
const router = useRouter();
const { t } = useI18n();

const { defineField, validate } = useForm({
    validationSchema: {
        country: "required|countryCode",
    },
});

const [country, countryProps] = defineField("country", (state: any) => ({
    props: {
        "error-messages": state.errors,
    },
}));

async function updateCountry() {
    const { valid } = await validate();

    if (valid) {
        await basketStore.updateBasket({ country: country.value });
        await router.push({
            name: "payment",
            params: {
                basketId: basketStore.basketId as string,
            },
        });
    }
}
</script>
