<template>
    <div class="evervault" ref="cardRef"></div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useTheme } from "vuetify";
import { useI18n } from "vue-i18n";
import { PaymentFormContext, usePaymentStore } from "@/store/payment";
import { useAppStore } from "@/store/app";
import { useMessageStore } from "@/store/message";
import { rgb, cssVar } from "@/helpers/css-var";
import { FieldsEvervault } from "@/types/PaymentMethodFields";
import "@adyen/adyen-web/dist/adyen.css";
import { toRef } from "vue";

interface Props {
    config: FieldsEvervault;
    form: PaymentFormContext;
}

const props = defineProps<Props>();

interface EvervaultCardPayload {
    card: {
        bin: string;
        brand: string;
        cvc: string;
        expiry: { month: string; year: string };
        lastFour: string;
        name: string;
        number: string;
    };
    errors: Record<string, string>;
    isComplete: boolean;
    isValid: boolean;
}

const theme = useTheme();
const { t, locale } = useI18n();

const paymentStore = usePaymentStore();
const messageStore = useMessageStore();
const appStore = useAppStore();

const cardRef = ref();

const form = toRef(props, "form");

function createEvervault() {
    const evervault = new Evervault(
        import.meta.env.VITE_EVERVAULT_TEAM_ID,
        import.meta.env.VITE_EVERVAULT_APP_ID,
    );

    const cardTheme = (utilities: any) => ({
        fonts: [
            "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&amp;display=swap",
        ],
        styles: {
            ":root": {
                "color-scheme": theme.global.name.value,
            },
            body: {
                fontFamily: "Lato",
                "-webkit-font-smoothing": "antialiased",
            },
            "[ev-component=card]": {
                // 4 columns
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
            },
            "[ev-component=card] .field": {
                // Fill first 2 columns
                gridColumn: "1 / 3",
            },
            ...utilities.media("(max-width: 700px)", {
                "[ev-component=card]": {
                    // 2 columns
                    gridTemplateColumns: "1fr 1fr",
                },
                "[ev-component=card] .field": {
                    // Fill first whole width
                    gridColumn: "1 / -1",
                },
            }),
            label: {
                fontSize: 14,
                fontWeight: 700,
                color: rgb(cssVar("--v-theme-gray-100")),
            },
            fieldset: {
                marginTop: 4,
            },
            ".field": {
                padding: "4px 0",
            },
            ".field input": {
                height: 38,
                marginBottom: 22,
                marginTop: 6,
                padding: "4px 12px",
                fontSize: 12,
                fontWeight: 400,
                fontFamily: "Lato",
                border: `1px solid ${rgb(cssVar("--v-theme-field-border"))}`,
                backgroundColor: rgb(cssVar("--v-theme-fields")),
                borderRadius: 2,
            },
            ".field input::placeholder": {
                color: "currentColor",
                opacity: 0.38,
            },
            ".field input:focus": {
                borderColor: rgb(cssVar("--v-theme-gray-600")),
            },
            ".field[ev-valid=false] input:not(:focus)": {
                borderColor: rgb(cssVar("--v-theme-error")),
            },
            ".field[ev-valid=false] input": {
                marginBottom: 0,
            },
            ".error": {
                fontSize: 12,
                color: rgb(cssVar("--v-theme-error")),
                paddingTop: 6,
                letterSpacing: "0.0333333333em",
                fontWeight: 400,
                minHeight: 22,
            },
        },
    });

    const instance = evervault.ui.card({
        theme: cardTheme,
    });

    watch(
        [locale],
        () => {
            instance.update({
                translations: {
                    name: {
                        label: t("payment.form.card.name.label"),
                        placeholder: t("payment.form.card.name.placeholder"),
                        errors: {
                            invalid: t("payment.form.card.name.errors.invalid"),
                        },
                    },
                    number: {
                        label: t("payment.form.card.number.label"),
                        placeholder: t("payment.form.card.number.placeholder"),
                        errors: {
                            invalid: t(
                                "payment.form.card.number.errors.invalid",
                            ),
                        },
                    },
                    expiry: {
                        label: t("payment.form.card.expiry.label"),
                        placeholder: t("payment.form.card.expiry.placeholder"),
                        errors: {
                            invalid: t(
                                "payment.form.card.expiry.errors.invalid",
                            ),
                        },
                    },
                    cvc: {
                        label: t("payment.form.card.cvc.label"),
                        placeholder: t("payment.form.card.cvc.placeholder"),
                        errors: {
                            invalid: t("payment.form.card.cvc.errors.invalid"),
                        },
                    },
                },
            });
        },
        { immediate: true },
    );

    return instance;
}

async function initEvervault() {
    const card = createEvervault();

    // Add the fields to prevent submission if they are not present
    form.value.registerField("number", "required");
    form.value.registerField("expiry.month", "required");
    form.value.registerField("expiry.year", "required");
    form.value.registerField("cvc", "required");

    watch(
        () => form.value.isSubmitting,
        (isSubmitting) => {
            if (isSubmitting) {
                card.validate();
            }
        },
    );

    card.on("change", (payload: EvervaultCardPayload) => {
        if (
            payload.card.bin &&
            appStore.config?.services.adyen.disallowedBins.includes(
                payload.card.bin,
            )
        ) {
            messageStore.addMessage(t("error.card_not_accepted"), {
                type: "error",
            });
        }

        form.value.setValues(
            {
                number: payload.card.number,
                expiry: payload.card.expiry,
                cvc: payload.card.cvc,
                brand: payload.card.brand,
                lastFour: payload.card.lastFour,
                bin: payload.card.bin,
                source: "evervault",
                paymentMethod: {
                    type: "scheme",
                },
                browserInfo: window.ThreedDS2Utils.getBrowserInfo(),
            },
            // Don't validate as evervault will validate for us
            false,
        );
    });

    return new Promise((resolve) => {
        card.mount(cardRef.value);
        card.on("ready", () => {
            resolve(card);
        });
    });
}

onMounted(async () => {
    await initEvervault();
    paymentStore.setFieldsReady(true);
});

onBeforeUnmount(() => {
    paymentStore.setFieldsReady(false);
});
</script>
