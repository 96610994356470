import AdyenCheckoutSdk from "@adyen/adyen-web";
import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import { PaymentMethodOptions } from "@adyen/adyen-web/dist/types/types";
import zoid from "@/composables/useZoid";

export type Adyen = Awaited<ReturnType<typeof AdyenCheckoutSdk>>;

export function createAdyenCheckout(options: CoreOptions) {
    return AdyenCheckoutSdk(options);
}

export function createGooglePayComponent(
    adyen: Adyen,
    options?: PaymentMethodOptions<"googlepay">,
) {
    return adyen.create("googlepay", options);
}

export async function isGooglePayAvailable(adyen: Adyen) {
    let isAvailable = false;

    try {
        isAvailable = await createGooglePayComponent(adyen).isAvailable();
    } catch (error) {
        isAvailable = false;
    }

    return isAvailable;
}

export function createApplePayComponent(
    adyen: Adyen,
    options?: PaymentMethodOptions<"applepay">,
) {
    return adyen.create("applepay", options);
}

export async function isApplePayAvailable(adyen: Adyen) {
    if (zoid.isChild()) {
        return window.xprops.isApplePayAvailable;
    }

    let isAvailable = false;

    try {
        isAvailable = await createApplePayComponent(adyen).isAvailable();
    } catch (error) {
        isAvailable = false;
    }

    return isAvailable;
}
