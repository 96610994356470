<template>
    <a href="#" @click.prevent="showDialog = !showDialog">
        {{ selectedLocale }}
    </a>

    <v-dialog v-model="showDialog" width="300" height="500">
        <v-list>
            <v-list-item
                v-for="(name, locale) in AVAILABLE_LOCALES"
                :key="locale"
                @click="setLocale(locale)"
                :active="name === selectedLocale"
            >
                <v-list-item-title>{{ name }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { AVAILABLE_LOCALES } from "@/plugins/i18n";
import { useAppStore } from "@/store/app";

const appStore = useAppStore();
const { locale } = useI18n();

const selectedLocale = computed(() => AVAILABLE_LOCALES[locale.value]);
const showDialog = ref(false);

const setLocale = async (newLocale: string) => {
    await appStore.setLocale(newLocale);
    showDialog.value = false;
};
</script>

<style lang="scss" scoped>
a {
    color: #9c9fa9;
}
</style>
