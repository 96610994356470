<template>
    <Evervault3DS
        v-if="data"
        mode="popup"
        :sessionId="data['3ds_id']"
        @success="onSuccess"
        @failure="onFail"
        @error="onError"
    ></Evervault3DS>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";

import { postCheckout } from "@/services/basket";
import { useBasketStore } from "@/store/basket";
import { useMessageStore } from "@/store/message";
import { usePaymentStore } from "@/store/payment";

import {
    SetupResponseEvervault3DSData,
    SetupResponseRedirect,
} from "@/types/SetupResponse";
import { AxiosResponse } from "axios";
import { isAxiosError } from "@/services/api";
import { useRedirect } from "@/composables/useRedirect";
import { useI18n } from "vue-i18n";
import Evervault3DS from "./Evervault3DS.vue";

interface Props {
    data: SetupResponseEvervault3DSData;
}

const props = defineProps<Props>();

const basketStore = useBasketStore();
const paymentStore = usePaymentStore();
const messageStore = useMessageStore();
const router = useRouter();
const { redirect } = useRedirect();
const { t } = useI18n();

async function onSuccess() {
    const data = paymentStore.getActiveForm()?.values;

    if (!data || !basketStore.basketId) {
        router.push({ name: "payment" });
        return;
    }
    // 3DS is complete and payment can be finalized
    try {
        const response = await postCheckout<SetupResponseRedirect>(
            basketStore.basketId,
            {
                // Default to the props
                ...data,
                // Add the 3DS txn_id to the payment
                txn_id: props.data.txn_id,
                evervault_3d_secure: true,
            },
        );

        // Redirect the user to the next step
        redirect(response.data);
    } catch (error) {
        if (isAxiosError(error)) {
            const response = error.response as AxiosResponse;

            messageStore.addMessage(
                response.data.detail ??
                    response.data.message ??
                    t("error.unexpected"),
                {
                    type: "error",
                },
            );
        } else {
            messageStore.addMessage(t("error.unexpected"), {
                type: "error",
            });
        }

        router.push({ name: "payment" });
    }
}

function onFail() {
    messageStore.addMessage(t("error.3ds_failure"), {
        type: "error",
        timeout: 15000,
    });

    router.push({ name: "payment" });
}

function onError(error: { code: string; message: string }) {
    messageStore.addMessage(error.message, {
        type: "error",
    });

    router.push({ name: "payment" });
}
</script>
