<template>
    <div>
        <h3 class="mb-4">{{ t("payment.setup_response.sepa.title") }}</h3>
        <div>
            <template v-for="(detail, key) in data.details" :key="key">
                <UseClipboard v-slot="{ copy, copied }" :source="detail">
                    <label :for="key">
                        {{ t(`payment.setup_response.sepa.${key}`) }}
                    </label>

                    <v-text-field
                        :id="key"
                        :name="key"
                        class="text-field-copy"
                        :model-value="detail"
                        readonly
                        :append-inner-icon="
                            copied ? 'mdi-check' : 'mdi-content-copy'
                        "
                        @click:append-inner="copy(detail)"
                        @click="copy(detail)"
                    ></v-text-field>
                </UseClipboard>
            </template>

            <UseClipboard v-slot="{ copy, copied }">
                <label for="amount">
                    {{ t("payment.setup_response.sepa.transferAmount") }}
                </label>
                <v-text-field
                    name="amount"
                    id="amount"
                    class="text-field-copy"
                    :model-value="`${data.currency} ${data.amount}`"
                    readonly
                    :append-inner-icon="
                        copied ? 'mdi-check' : 'mdi-content-copy'
                    "
                    @click:append-inner="copy(data.amount)"
                    @click="copy(data.amount)"
                ></v-text-field>
            </UseClipboard>
        </div>

        <div class="d-flex justify-space-between mt-3">
            <v-btn @click="back" color="secondary">
                {{ t("buttons.cancel_transfer") }}
            </v-btn>
            <v-btn color="primary" @click="showDialog = true">
                {{ t("buttons.confirm_sent_payment") }}
            </v-btn>
        </div>
    </div>

    <v-dialog width="500" v-model="showDialog" @ok="confirm">
        <v-card>
            <v-card-text>
                {{
                    t(
                        "payment.setup_response.sepa.confirm_transfer_description",
                    )
                }}
            </v-card-text>

            <v-card-actions>
                <v-btn @click="confirm">{{
                    t("buttons.confirm_sent_payment")
                }}</v-btn>
                <v-btn @click="showDialog = false">{{
                    t("buttons.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { usePaymentStore } from "@/store/payment";
import { SetupResponseSepaData } from "@/types/SetupResponse";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { UseClipboard } from "@vueuse/components";
import { useI18n } from "vue-i18n";

interface Props {
    data: SetupResponseSepaData;
}

defineProps<Props>();

const router = useRouter();
const paymentStore = usePaymentStore();
const { t } = useI18n();

const back = () => {
    paymentStore.setupResponse = null;

    router.push({
        name: "payment",
    });
};

const confirm = () => {
    paymentStore.completePayment();
};

const showDialog = ref(false);
</script>

<style lang="scss">
.text-field-copy .v-field,
.text-field-copy input {
    cursor: pointer;
}
</style>
