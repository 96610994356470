<template>
    <span class="loader"></span>
</template>

<style lang="scss" scoped>
.loader {
    width: 35px;
    height: 35px;
    border: 5px solid rgb(var(--v-theme-gray-600));
    border-bottom-color: rgb(var(--v-theme-gray-300));
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
