<template>
    <label for="coupon">{{ t("basket.coupon") }}</label>
    <v-text-field
        v-model="coupon"
        ref="couponField"
        id="coupon"
        name="coupon"
        placeholder="000-000-000"
        :class="{ 'has-button': coupon.length > 0 }"
        :loading="couponCodeLoading"
        @keyup.enter="applyDiscountCode"
        :error-messages="couponErrorMessage"
    >
        <template v-slot:append>
            <v-btn
                v-if="coupon.length > 0"
                @click="applyDiscountCode"
                color="secondary"
                name="apply_coupon_code"
                :disabled="couponCodeLoading"
            >
                {{ t("buttons.confirm") }}
            </v-btn>
        </template>
    </v-text-field>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useBasketStore } from "@/store/basket";
import { ref, type Ref } from "vue";
import { computed } from "vue";
import { VTextField } from "vuetify/lib/components/index.mjs";
import { getErrorMessage } from "@/helpers/error";

const { t } = useI18n();
const basketStore = useBasketStore();

const couponField = ref<InstanceType<typeof VTextField> | null>(null);
const coupon: Ref<string> = ref("");
const couponCodeError: Ref<string> = ref("");
const couponCodeLoading: Ref<boolean> = ref(false);

const couponErrorMessage = computed(() => {
    return couponCodeError.value ? [couponCodeError.value] : [];
});

const applyDiscountCode = async () => {
    couponCodeLoading.value = true;
    couponCodeError.value = "";
    couponField.value?.validate();

    try {
        await basketStore.applyDiscountCode(coupon.value);
    } catch (error) {
        couponCodeError.value =
            getErrorMessage(error) ?? "Invalid code, Please try again.";
    }

    couponCodeLoading.value = false;
    coupon.value = "";
};
</script>
