<template>
    <div class="d-flex flex-column h-100 align-center justify-center">
        <Loader class="mb-8" />

        <div class="text-center mb-11">
            {{ t("payment.payment_waiting") }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRedirect } from "@/composables/useRedirect";
import { useZoid } from "@/composables/useZoid";
import { usePaymentStore } from "@/store/payment";
import Loader from "@/components/app/Loader.vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{ data: string }>();

const zoid = useZoid();
const { redirect, redirectInNewTab } = useRedirect();
const paymentStore = usePaymentStore();
const { t } = useI18n();

onMounted(async () => {
    // If in the popup redirect the current user to the payment page
    if (zoid.isChild()) {
        // Wait for the user to close the page
        await redirectInNewTab(props.data);

        // Once they have closed the page we can check if the payment was successful
        await paymentStore.completePayment();
    } else {
        // If not in the popup redirect the user to the payment page.
        // The user will be redirected back to the app once they have completed the payment
        redirect(props.data);
    }
});
</script>
