import * as zoid from "zoid/dist/zoid.frame.js";
import type { Zoid } from "zoid";
import { inject } from "vue";

// Setup zoid to enable cross domain communication with popup
export function setupZoid(): Zoid {
    return zoid.create({
        tag: "tebex-js-checkout-component",
        url: new URL(window.location.href).href,
        props: {
            onClosePopup: {
                type: "function",
                required: false,
            },
            onPaymentComplete: {
                type: "function",
                required: false,
            },
            colors: {
                type: "array",
                required: false,
            },
            theme: {
                type: "string",
                required: false,
            },
        },
    });
}

export function destroyZoid(): void {
    zoid.destroy();
}

const zoidInstance = setupZoid();

export function useZoid(): Zoid {
    const zoid = inject<Zoid | null>("zoid", null);

    if (!zoid) {
        throw new Error("Zoid not found");
    }

    return zoid;
}

export default zoidInstance;
