<template>
    <v-combobox
        v-model="model"
        item-title="name"
        item-value="code"
        :items="items"
        @blur="emit('blur', $event)"
        :auto-select-first="true"
        :return-object="false"
        v-bind="$attrs"
    ></v-combobox>
</template>

<script lang="ts" setup>
import countryList, { type Country } from "country-list";

defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue", "blur"]);
const model = defineModel<Country | string>();
const items = countryList.getData();
</script>
