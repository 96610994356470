<template>
    <span v-if="error">
        {{ error }}
    </span>
</template>

<script lang="ts" setup>
import {
    onMounted,
    inject,
    Ref,
    ComponentPublicInstance,
    watch,
    onBeforeUnmount,
    unref,
    toRef,
    ref,
} from "vue";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import { PaymentFormContext, usePaymentStore } from "@/store/payment";
import { type Adyen, createGooglePayComponent } from "@/services/adyen";
import "@adyen/adyen-web/dist/adyen.css";
import { useTheme } from "vuetify";
import { FieldsGooglePay } from "@/types/PaymentMethodFields";
import { useZoid } from "@/composables/useZoid";
import { useAdyen } from "@/composables/useAdyen";
import UIElement from "@adyen/adyen-web/dist/types/components/UIElement";
import {
    PaymentAmount,
    PaymentMethodOptions,
} from "@adyen/adyen-web/dist/types/types";
import { getErrorMessage } from "@/helpers/error";
import { useI18n } from "vue-i18n";

interface Props {
    config: FieldsGooglePay;
    form: PaymentFormContext;
    fieldsUpdating: Set<string>;
}

const props = defineProps<Props>();
const form = unref(props.form);

const theme = useTheme();
const paymentStore = usePaymentStore();
const appStore = useAppStore();
const basketStore = useBasketStore();

const submitButtonContainer = inject<Ref<ComponentPublicInstance>>(
    "submitButtonContainer",
);

const submitHandler = inject<Function>("submitHandler");
const fieldsUpdating = toRef(props, "fieldsUpdating");

let component: UIElement<PaymentMethodOptions<"googlepay">> | null = null;

const error = ref<string | null>(null);
const { t } = useI18n();

function calcAmount() {
    if (appStore.config === null) {
        throw new Error("No app config found");
    }

    if (basketStore.price === null) {
        throw new Error("Basket price found");
    }

    return {
        currency: appStore.config.currency.code,
        value: Math.round(basketStore.price.balance * 100),
    };
}

function createGooglePay(checkout: Adyen) {
    let amount: PaymentAmount;

    try {
        amount = calcAmount();
    } catch (err) {
        // If we can't calculate the amount, we can't continue
        error.value = import.meta.env.DEV
            ? getErrorMessage(err)
            : t("error.unexpected");
        return;
    }

    component = createGooglePayComponent(checkout, {
        environment: import.meta.env.DEV ? "TEST" : "PRODUCTION",
        amount,
        configuration: {
            gatewayMerchantId:
                basketStore.address?.country == "US"
                    ? "TebexUS"
                    : "TebexCheckout",
            merchantId: "BCR2DN4T52WIB3QE",
            merchantName: "TEBEX.ORG",
        },
        countryCode: basketStore.address?.country == "US" ? "US" : "GB",
        buttonColor: theme.current.value.dark ? "white" : "black",
        buttonSizeMode: "fill",
        async onClick(resolve) {
            // Don't submit the form if the user is updating their post code
            // This is because the amount may change if the user is updating their address
            if (fieldsUpdating.value.has("postalCode")) {
                return;
            }

            const result = await form.validate();

            if (result?.valid) {
                resolve();
            }
        },
        onChange(state: any) {
            form.setValues(state.data, false);

            if (state.isValid && submitHandler) {
                submitHandler();
            }
        },
    });

    if (!submitButtonContainer?.value || !submitButtonContainer.value.$el) {
        throw new Error("No submit button container found");
    }

    component.mount(submitButtonContainer.value.$el);
}

const zoid = useZoid();

const { load } = useAdyen({
    manual: true, // We don't want to load Adyen until we are ready
    onCheckoutCreate: createGooglePay,
});

watch(
    () => basketStore.price,
    () => {
        if (!component) {
            return;
        }

        const amount = calcAmount();

        // Update the amount if the total changes
        component.update({
            amount,
        });
    },
);

onMounted(async () => {
    // If we are within the popup, we don't need to load
    if (
        (!zoid.isChild() || !zoid.xprops.isEmbedded) &&
        !appStore.config?.testMode
    ) {
        await load();
    }

    setTimeout(() => {
        paymentStore.setFieldsReady(true);
    }, 400);
});

onBeforeUnmount(() => {
    paymentStore.setFieldsReady(false);
});
</script>
