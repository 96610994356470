import { Component, h } from "vue";
import type { IconSet, IconProps } from "vuetify";

const svgs = import.meta.glob<Component>("../assets/icons/*.svg", {
    // See https://vitejs.dev/guide/features.html#glob-import
    eager: true,
    import: "default",
});

const aliases: any = {};

Object.entries(svgs).forEach(async ([path, module]) => {
    const iconName = path
        .toLowerCase()
        .replace("../assets/icons/", "")
        .replace(".svg", "");

    aliases[iconName] = module;
});

const custom: IconSet = {
    component: (props: IconProps) => {
        return h(props.tag, [
            h(aliases[props.icon as string], {
                style: { fill: "currentColor" },
            }),
        ]);
    },
};

export { aliases, custom };
