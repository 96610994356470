/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Types
import type { App } from "vue";

// Plugins
import vuetify from "./vuetify";
import router from "./router";
import pinia from "./pinia";
import i18n from "./i18n";
import { createHead } from "@unhead/vue";
import sentry from "./sentry";

import "./validation";

export function registerPlugins(app: App) {
    app.use(sentry, { router });
    app.use(vuetify);
    app.use(pinia);
    app.use(router);
    app.use(i18n);
    app.use(createHead());
}
