<template>
    <span ref="target">
        <slot></slot>
    </span>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useWindowPortal } from "@/composables/useWindowPortal";
import { useVModel } from "@vueuse/core";

const props = defineProps<{ modelValue: boolean }>();
const emit = defineEmits(["update:modelValue", "window:close", "window:open"]);

const target = ref(null);
const portal = useWindowPortal(target, emit);
const model = useVModel(props, "modelValue", emit);

watch(
    () => model.value,
    (newValue) => {
        if (newValue) {
            portal.open();
        } else {
            portal.close();
        }
    },
    { immediate: true },
);

defineExpose({
    windowRef: portal.windowRef,
});
</script>
