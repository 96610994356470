<template>
    <v-checkbox
        v-if="form.fields.instructions"
        v-model="form.fields.instructions.model"
        v-bind="form.fields.instructions.props"
        density="compact"
        :hide-details="form.isFieldValid('instructions')"
        class="mt-2"
    >
        <template v-slot:label>
            {{ config.fields.instructions_plain }}
        </template>
    </v-checkbox>
</template>

<script lang="ts" setup>
import { FieldsInstructions } from "@/types/PaymentMethodFields";
import { PaymentFormContext, usePaymentStore } from "@/store/payment";
import { onBeforeUnmount, onMounted, toRef } from "vue";
interface Props {
    form: PaymentFormContext;
    config: FieldsInstructions;
}

const props = defineProps<Props>();
const form = toRef(() => props.form);

const paymentStore = usePaymentStore();

onMounted(() => {
    form.value.registerField("instructions", "required");
    paymentStore.setFieldsReady(true);
});

onBeforeUnmount(() => {
    form.value.unregisterField("instructions");
    paymentStore.setFieldsReady(false);
});
</script>
