<template>
    <ErrorPage :title="t('404.title')" :description="t('404.description')" />
</template>

<script setup lang="ts">
import ErrorPage from "@/pages/error/ErrorPage.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
