import { defineStore } from "pinia";
import { reactive, watch } from "vue";

interface MessageOptions {
    type?: "success" | "error" | "info" | "warning";
    timeout?: number;
}

interface Message {
    id: string;
    message: string;
    show: boolean;
    options: MessageOptions;
}

interface State {
    messages: Message[];
    addMessage: (message: string, options: MessageOptions) => void;
    removeMessage: (toast: Message) => void;
}

let id = 0;

export const useMessageStore = defineStore("message", function (): State {
    const messages: Message[] = reactive([]);

    watch(messages, (toasts) => {
        toasts.forEach((toast) => {
            if (toast.options.timeout) {
                setTimeout(() => {
                    toast.show = false;

                    // Wait until the animation has finished
                    setTimeout(() => {
                        removeMessage(toast);
                    }, 500);
                }, toast.options.timeout);
            }
        });
    });

    function addMessage(message: string, options: MessageOptions) {
        const _options: MessageOptions = {
            timeout: 5000,
            type: "success",
            ...options,
        };

        messages.push({
            id: `message-${id++}`,
            message,
            show: true,
            options: _options,
        });
    }

    function removeMessage(toast: Message) {
        const index = messages.findIndex((t) => t.id === toast.id);

        if (index > -1) {
            messages.splice(index, 1);
        }
    }

    return {
        messages,
        addMessage,
        removeMessage,
    };
});
