<template>
    <v-navigation-drawer
        v-if="smAndUp"
        permanent
        :width="sidebarWidth"
        border="none"
        touchless
    >
        <div class="container ml-auto">
            <Logo v-if="mdAndUp && !basketStore.shouldShowLoader" />
            <router-view name="sidebar" />
        </div>
    </v-navigation-drawer>

    <v-main :scrollable="smAndUp">
        <div class="container h-100 mr-auto">
            <v-sheet class="pa-6 d-flex flex-column" width="100%" height="100%">
                <div class="d-flex justify-center" v-if="!smAndUp">
                    <Logo class="mobile-logo" />
                </div>
                <router-view v-if="!smAndUp" name="overview" />
                <v-skeleton-loader
                    :loading="
                        basketStore.shouldShowLoader ||
                        upsellStore.shouldShowLoader
                    "
                    type="text"
                >
                    <Breadcrumbs
                        v-if="
                            $route.meta.breadcrumbs !== false &&
                            !basketStore.isPaymentInProgress
                        "
                        :items="breadcrumbs"
                    />
                </v-skeleton-loader>
                <router-view />
            </v-sheet>
        </div>
    </v-main>

    <Footer />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useResizeObserver, useDebounceFn } from "@vueuse/core";
import { ref, type Ref, computed } from "vue";
import { useDisplay } from "vuetify";
import Breadcrumbs, { BreadcrumbItem } from "@/components/app/Breadcrumbs.vue";
import Footer from "@/components/app/Footer.vue";
import Logo from "@/components/app/Logo.vue";
import { VMain } from "vuetify/lib/components/index.mjs";
import { useBasketStore } from "@/store/basket";
import { useUpsellStore } from "@/store/upsell";
const { t } = useI18n();

const { smAndUp, mdAndUp } = useDisplay();
const basketStore = useBasketStore();
const upsellStore = useUpsellStore();

const breadcrumbs = computed(() => {
    let items: BreadcrumbItem[] = [
        {
            title: basketStore.isPaymentMethodUpdate
                ? t("breadcrumbs.update_payment_method")
                : t("breadcrumbs.payment"),
            to:
                basketStore.address?.country === "XX"
                    ? undefined
                    : { name: "payment" },
        },
        {
            title: basketStore.isPaymentMethodUpdate
                ? t("breadcrumbs.confirmation")
                : t("breadcrumbs.order_confirmed"),
        },
    ];

    if (basketStore.address?.country === "XX") {
        items.unshift({
            title: t("breadcrumbs.select_country"),
            to: { name: "country-selection" },
        });
    }

    if (upsellStore.items.length !== 0) {
        items.unshift({
            title: t("breadcrumbs.upsell"),
            to: { name: "basket" },
            exact: true,
        });
    }

    return items;
});

const sidebarWidth: Ref<number> = ref(
    mdAndUp.value ? document.body.clientWidth * 0.5 : 260,
);

useResizeObserver(
    document.body,
    useDebounceFn((entries) => {
        const entry = entries[0];
        sidebarWidth.value = mdAndUp.value
            ? entry.contentRect.width * 0.5
            : 260;
    }, 5),
);
</script>

<style scoped lang="scss">
@use "@/styles/settings";

.v-layout {
    @media #{map-get(settings.$display-breakpoints, 'sm-and-down')} {
        flex-direction: column;
    }
}

.v-navigation-drawer__content > .container,
.v-main__scroller > .container,
.v-main > .container {
    margin: 0 auto;
    height: 100%;

    @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
        padding-top: 80px;
    }

    @media #{map-get(settings.$display-breakpoints, 'md')} {
        padding: 80px 20px 0 20px;
    }

    @media #{map-get(settings.$display-breakpoints, 'lg-and-up')} {
        &.ml-auto {
            margin-right: 50px;
        }

        &.mr-auto {
            margin-left: 50px;
        }
    }

    @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
        max-width: calc(475px + (36px * 2));
    }
}

.v-navigation-drawer {
    .container {
        @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
            padding-bottom: 110px;

            .logo {
                margin-top: -37px;
            }
        }
    }
}

.v-main {
    .container {
        height: auto;
    }
}

.mobile-logo {
    margin: 0 auto;
    margin-bottom: 44px;
}
</style>
