<template>
    <div ref="container"></div>
</template>

<script setup lang="ts">
import { useAdyen } from "@/composables/useAdyen";
import { SetupResponseAdyenVoucherData } from "@/types/SetupResponse";
import { onMounted, ref } from "vue";

interface Props {
    data: SetupResponseAdyenVoucherData;
}

const props = defineProps<Props>();
const container = ref<HTMLDivElement | null>(null);

const onAdditionalDetails = (state: any) => {
    const payload = encodeURIComponent(state.data.details.payload);
    document.location.href = props.data.completeUrl + "?payload=" + payload;
};

const { load } = useAdyen({ manual: true });

onMounted(async () => {
    if (container.value) {
        const checkout = await load();

        checkout.update({
            onAdditionalDetails,
        });

        checkout.createFromAction(props.data.action).mount(container.value);
    }
});
</script>
