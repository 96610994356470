<template>
    <v-breadcrumbs
        class="mb-6"
        :color="theme.current.value.dark ? 'gray-700' : 'gray-600'"
    >
        <template v-for="(item, index) in items" :key="item.title">
            <v-breadcrumbs-item v-bind="item"></v-breadcrumbs-item>
            <v-breadcrumbs-divider v-if="index !== items.length - 1">
                <svg
                    viewBox="0 0 3 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.40003 3.29997C2.53336 3.12219 2.53336 2.87775 2.40003 2.69997L0.900029 0.69997C0.734344 0.479056 0.420943 0.434285 0.200029 0.59997C-0.0208846 0.765656 -0.0656563 1.07906 0.100029 1.29997L1.37503 2.99997L0.100029 4.69997C-0.0656561 4.92088 -0.0208843 5.23429 0.20003 5.39997C0.420944 5.56566 0.734344 5.52088 0.90003 5.29997L2.40003 3.29997Z"
                    />
                </svg>
            </v-breadcrumbs-divider>
        </template>
    </v-breadcrumbs>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify";

export interface BreadcrumbItem {
    title: string;
    to?: string | { name: string };
    disabled?: boolean;
    active?: boolean;
    exact?: boolean;
}

interface Props {
    items: BreadcrumbItem[];
}

defineProps<Props>();

const theme = useTheme();
</script>

<style scoped lang="scss">
@use "@/styles/mixins/theme";

.v-breadcrumbs-divider {
    display: inline-flex;

    svg {
        width: 4px;

        path {
            @include theme.dark {
                fill: rgb(var(--v-theme-gray-700));
            }

            @include theme.light {
                fill: rgb(var(--v-theme-gray-600));
            }
        }
    }
}
</style>
