import i18n, { loadLocaleMessages, setI18nLanguage } from "@/plugins/i18n";
import { getAppConfig } from "@/services/app";
import { AppConfig } from "@/types/AppConfig";
import { useAsyncState } from "@vueuse/core";
import { defineStore } from "pinia";
import { type Ref, computed, ref, watch } from "vue";
import { useUrlSearchParams } from "@vueuse/core";
import { useCookies } from "@vueuse/integrations/useCookies";
import api from "@/services/api";

interface State {
    config: Ref<AppConfig | null>;
    isReady: Ref<boolean>;
    isConfigLoading: Ref<boolean>;
    error: Ref<unknown>;
    locale: Ref<string>;
    isApiRunning: Ref<boolean>;
    fetchAppConfig: (
        basketId: string,
        referrer?: string,
    ) => Promise<AppConfig | null>;
    setLocale: (newLocale: string) => void;
    watchForLocaleChange: () => void;
}

export const useAppStore = defineStore("app", function (): State {
    const { state, isReady, isLoading, execute, error } = useAsyncState<
        AppConfig | null,
        [{ basketId: string; referrer?: string }],
        false
    >(
        async ({ basketId, referrer }) => getAppConfig(basketId, referrer),
        null,
        {
            immediate: false,
            throwError: true,
        },
    );

    const isConfigLoading = computed(() => isLoading.value);

    const locale = ref();
    const params = useUrlSearchParams("history");
    const cookies = useCookies(["locale"]);

    async function setLocale(newLocale: string) {
        try {
            await loadLocaleMessages(i18n, newLocale);
            locale.value = newLocale;
            setI18nLanguage(i18n, newLocale);
            cookies.set("locale", newLocale);
        } catch (e) {
            console.error(e);
        }
    }

    const watchForLocaleChange = () => {
        // Set the locale from the URL or cookie
        watch(
            () => params.locale,
            (newLocale) => {
                let locale = "en_US";

                if (newLocale) {
                    locale = Array.isArray(newLocale)
                        ? newLocale[0]
                        : newLocale;
                }

                setLocale(locale);
            },
            { immediate: true },
        );
    };

    const isApiRunning = ref(false);

    api.interceptors.request.use(
        function (config) {
            isApiRunning.value = true;
            // show loader
            return config;
        },
        function (error) {
            isApiRunning.value = false;
            return Promise.reject(error);
        },
    );

    api.interceptors.response.use(
        function (config) {
            isApiRunning.value = false;
            // show loader
            return config;
        },
        function (error) {
            isApiRunning.value = false;
            return Promise.reject(error);
        },
    );

    return {
        config: state,
        isReady,
        isConfigLoading,
        error,
        locale,
        isApiRunning,
        fetchAppConfig: (basketId: string, referrer?: string) =>
            execute(0, { basketId, referrer }),
        setLocale,
        watchForLocaleChange,
    };
});
