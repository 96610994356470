<template>
    <label for="creator_code">{{ t("basket.creator_code") }}</label>
    <v-text-field
        v-if="!basketStore.creatorCode"
        v-model="creatorCode"
        id="creator_code"
        name="creator_code"
        placeholder="000-000-000"
        :class="{ 'has-button': creatorCode.length > 0 }"
        :loading="creatorCodeLoading"
        @keyup.enter="applyCreatorCode"
        :error-messages="creatorCodeErrorMessage"
    >
        <template v-slot:append>
            <v-btn
                v-if="creatorCode.length > 0"
                @click="applyCreatorCode"
                color="secondary"
                name="apply_creator_code"
                :disabled="creatorCodeLoading"
            >
                {{ t("buttons.confirm") }}
            </v-btn>
        </template>
    </v-text-field>

    <template v-else-if="basketStore.creatorCode">
        <v-alert
            variant="tonal"
            type="success"
            class="border border-success"
            :text="basketStore.creatorCode.code"
            density="compact"
            closable
            height="38"
            data-testid="creator_code_applied"
        >
            <template v-slot:close>
                <v-btn
                    v-if="!creatorCodeLoading"
                    @click="removeCreatorCode"
                    color="success"
                    class="mr-n4"
                    small
                    data-testid="remove_creator_code"
                >
                    <v-icon small>mdi-close</v-icon>
                </v-btn>

                <v-progress-circular
                    v-else
                    :size="16"
                    :width="3"
                    indeterminate
                ></v-progress-circular>
            </template>
        </v-alert>
        <div class="creator-code-success text-success">
            {{ t("basket.creator_code_applied") }}
        </div>
    </template>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useBasketStore } from "@/store/basket";
import { ref, type Ref } from "vue";
import { computed } from "vue";
import { getErrorMessage } from "@/helpers/error";

const { t } = useI18n();
const basketStore = useBasketStore();

const creatorCode: Ref<string> = ref("");
const creatorCodeLoading: Ref<boolean> = ref(false);
const creatorCodeError: Ref<string> = ref("");
const creatorCodeErrorMessage = computed(() => {
    return creatorCodeError.value ? [creatorCodeError.value] : [];
});

const applyCreatorCode = async () => {
    creatorCodeLoading.value = true;
    creatorCodeError.value = "";

    try {
        await basketStore.applyCreatorCode(creatorCode.value);
    } catch (error) {
        creatorCodeError.value =
            getErrorMessage(error) ?? "Invalid code, Please try again.";
    }

    creatorCodeLoading.value = false;
};

const removeCreatorCode = async () => {
    creatorCodeLoading.value = true;

    try {
        await basketStore.removeCreatorCode();

        creatorCodeError.value = "";
    } catch (e) {
        console.error(e);
    }

    creatorCodeLoading.value = false;
};
</script>

<style scoped lang="scss">
:deep(.v-alert__close),
:deep(.v-alert__prepend) {
    align-self: center;

    display: flex;
    align-items: center;
}

.v-alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

:deep(.v-alert__content) {
    flex-grow: 1;
    line-height: 1;
}

:deep(.v-alert--density-compact) {
    padding: 0 12px;

    .v-icon {
        width: 20px !important;
        height: 20px !important;
        font-size: 20px !important;
    }
}

.creator-code-success {
    line-height: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    font-size: 12px;
    min-height: 22px;
    padding-top: 6px;
}
</style>
