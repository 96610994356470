<template>
    <v-footer
        :height="width > 780 ? 60 : 105"
        :app="smAndUp"
        order="-1"
        name="footer"
        class="flex-wrap"
    >
        <a
            :href="
                'https://www.tebex.io?utm_source=checkout&utm_campaign=' +
                    appStore.config?.id ?? ''
            "
            target="_blank"
            class="footer-logo-link align-self-center"
        >
            <img src="/logo-gray.svg" alt="Tebex Logo" class="footer-logo" />
        </a>
        <span class="d-flex align-center description">
            {{ t("footer.description") }}
        </span>
        <div class="d-flex footer-links">
            <a
                :href="`https://checkout.tebex.io/baskets/${basketStore.basketId}/refund-policy`"
                target="_blank"
            >
                {{ t("footer.links.refund_policy") }}
            </a>
            <a href="https://checkout.tebex.io/impressum" target="_blank">
                {{ t("footer.links.impressum") }}
            </a>
            <a href="https://checkout.tebex.io/terms" target="_blank">
                {{ t("footer.links.terms_and_conditions") }}
            </a>
            <a href="https://checkout.tebex.io/privacy" target="_blank">
                {{ t("footer.links.privacy_policy") }}
            </a>
            <LocaleSwitcher />
        </div>
    </v-footer>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";
import LocaleSwitcher from "./LocaleSwitcher.vue";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";

const { width, smAndUp } = useDisplay();
const { t } = useI18n();
const appStore = useAppStore();
const basketStore = useBasketStore();
</script>

<style lang="scss" scoped>
@use "@/styles/settings";

.footer-logo {
    width: 58px;
    margin-right: 27px;
}

.v-footer {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-areas:
        "logo desc"
        "links links";
    font-size: settings.$footer-font-size;
    max-height: 105px;
    align-items: center;

    @media #{map-get(settings.$display-breakpoints, 'sm-and-up')} {
        grid-template-areas: "logo desc links";
        grid-template-columns: 80px minmax(auto, 285px) 1fr;
    }

    @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
        grid-template-columns: 80px 1fr 1fr;
    }

    a {
        color: #9c9fa9;
    }

    @at-root {
        .v-theme--dark & {
            background-color: #0a0a0c;
            color: #9c9fa9;
        }
    }
}

.footer-links {
    grid-area: links;
    flex-wrap: wrap;
    gap: 0 16px;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;

    @media #{map-get(settings.$display-breakpoints, 'sm-and-up')} {
        justify-content: flex-end;
    }
}

.footer-logo-link {
    grid-area: logo;
}

.footer-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
}

.description {
    grid-area: desc;
    color: #9c9fa9;
}
</style>
