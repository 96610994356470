import {
    createSharedComposable,
    tryOnBeforeUnmount,
    useScriptTag,
} from "@vueuse/core";
import {
    MaybeRef,
    Ref,
    ShallowRef,
    inject,
    onBeforeUnmount,
    provide,
    ref,
    shallowRef,
    watchEffect,
} from "vue";

const useRoktScript = createSharedComposable((onLoad: () => void) =>
    useScriptTag(
        "https://apps.rokt.com/wsdk/integrations/launcher.js",
        onLoad,
        {
            async: true,
            crossOrigin: "anonymous",
            attrs: {
                id: "rokt-launcher",
                importance: "high",
            },
            manual: true,
        },
    ),
);

export const RoktLauncherSymbol = Symbol("RoktLauncher");

export const createRoktLauncher = (
    accountId: MaybeRef<string | null>,
    sandbox: boolean,
    enabled: Ref<boolean>,
) => {
    const launcher: ShallowRef<RoktLauncher | null> = shallowRef(null);
    const launcherSet = ref(false);
    const loaded = ref(false);

    const { load, unload } = useRoktScript(() => {
        loaded.value = true;
    });

    watchEffect(async () => {
        if (!accountId || !enabled.value) {
            return;
        }

        if (!loaded.value) {
            await load();
        }

        if (loaded.value && !launcherSet.value) {
            launcherSet.value = true;
            launcher.value = await window.Rokt.createLauncher({
                accountId: accountId,
                sandbox: sandbox,
            });
        }
    });

    onBeforeUnmount(() => {
        if (launcher.value) {
            launcher.value.terminate();
            launcher.value = null;
        }

        if (loaded.value) {
            unload();
        }
    });

    provide(RoktLauncherSymbol, launcher);
};

export const useRokt = (): ShallowRef<RoktLauncher | null> => {
    const launcher = inject(RoktLauncherSymbol, null);

    if (!launcher) {
        throw new Error(
            "RoktLauncher not provided, have you called createRoktLauncher?",
        );
    }

    return launcher;
};
