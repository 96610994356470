<template>
    <div v-if="!error" ref="googlePay"></div>
    <div v-else>{{ error }}</div>
</template>

<script setup lang="ts">
import { Ref, ref } from "vue";
import { useTheme } from "vuetify";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import { type Adyen, createGooglePayComponent } from "@/services/adyen";
import "@adyen/adyen-web/dist/adyen.css";
import { useAdyen } from "@/composables/useAdyen";

const theme = useTheme();
const appStore = useAppStore();
const basketStore = useBasketStore();

const googlePay = ref(null);
const error: Ref<string | null> = ref(null);

const emit = defineEmits(["change"]);

function createGooglePay(checkout: Adyen) {
    if (appStore.config === null) {
        error.value = "No config found";
        return;
    }

    if (basketStore.price === null) {
        error.value = "No balance found";
        return;
    }

    const amount = {
        currency: appStore.config.currency.code,
        value: Math.round(basketStore.price.balance * 100),
    };

    const component = createGooglePayComponent(checkout, {
        environment: import.meta.env.DEV ? "TEST" : "PRODUCTION",
        amount,
        configuration: {
            gatewayMerchantId:
                basketStore.address?.country == "US"
                    ? "TebexUS"
                    : "TebexCheckout",
            merchantId: "BCR2DN4T52WIB3QE",
            merchantName: "TEBEX.ORG",
        },
        countryCode: basketStore.address?.country == "US" ? "US" : "GB",
        buttonColor: theme.current.value.dark ? "white" : "black",
        buttonSizeMode: "fill",
        async onChange(state: any) {
            if (state.isValid && basketStore.basketId) {
                component.setStatus("loading");
                emit("change", state.data);
            }
        },
    });

    component.isAvailable().then((available) => {
        if (available && googlePay.value) {
            component.mount(googlePay.value);
        }
    });
}

useAdyen({ onCheckoutCreate: createGooglePay });
</script>
