import UpsellItem from "@/types/UpsellItem";
import api from "./api";
import { AxiosResponse } from "axios";

interface UpsellResponse {
    data: {
        uuid: string;
        package_name: string;
        package_id: number;
        image_url: string;
        price: number;
        offer_price: number;
        type: string;
        quantity: number;
        replacementPackageId: number;
        // TODO: Fix this any
        options: any[];
    }[];
}

export const getUpsells = (basketId: string): Promise<UpsellItem[]> => {
    return api
        .get(`/baskets/${basketId}/upsells`)
        .then((response: AxiosResponse<UpsellResponse>) => {
            const { data } = response.data;

            return data.map((item) => ({
                uuid: item.uuid,
                packageId: item.package_id,
                name: item.package_name,
                price: item.price,
                offerPrice: item.offer_price,
                quantity: item.quantity,
                image: item.image_url,
                options: item.options,
            }));
        });
};

export const addUpsellToBasket = (
    basketId: string,
    upsellId: string,
    options?: Record<string, string>,
): Promise<void> => {
    return api.post(`/baskets/${basketId}/upsells/`, {
        uuid: upsellId,
        options,
    });
};

interface ValidationResponse {
    status: number;
    type: string;
    title: string;
    detail: string;
    error_code: string;
}

export const validateOptions = (
    basketId: string,
    packageId: number,
    // The list of options is a key-value pair of option name and option value
    options: Record<string, string>,
): Promise<ValidationResponse> => {
    return api.post(
        `/baskets/${basketId}/packages/${packageId}/options/validate`,
        { options },
    );
};
