import { useAdyen } from "@/composables/useAdyen";
import zoid from "@/composables/useZoid";
import { popup } from "@/helpers/popup";
import router from "@/plugins/router";
import { isApplePayAvailable, isGooglePayAvailable } from "./adyen";
import { PaymentMethodDetails } from "@/types/PaymentMethod";
import { getPaymentMethod } from "./payment-method";

export interface LocalPaymentMethod {
    ident: string;
    name: string;
    image: string;
    transparentImage: string;
    details: PaymentMethodDetails;
    countries: string[];

    redirect: (basketId: string, data: Record<string, any>) => Promise<void>;
    shouldRedirect: () => boolean;
    isAvailable: () => Promise<boolean>;
    select: (basketId: string) => Promise<any>;
}

export class LocalPaymentMethodManager {
    private methods: Map<string, LocalPaymentMethod> = new Map();

    addMethod(method: LocalPaymentMethod): void {
        this.methods.set(method.ident, method);
    }

    getMethod(ident: string): LocalPaymentMethod | undefined {
        return this.methods.get(ident);
    }

    async selectMethod(
        ident: string,
        basketId: string,
    ): Promise<LocalPaymentMethod> {
        const method = this.methods.get(ident);

        if (!method) {
            throw new Error(`Local payment method ${ident} not found`);
        }

        // Select the payment method
        await method.select(basketId);

        return method;
    }

    redirect(
        ident: string,
        basketId: string,
        data: Record<string, any>,
    ): Promise<void> | undefined {
        const method = this.methods.get(ident);

        if (method) {
            return method.redirect(basketId, data);
        }
    }

    getAll(): IterableIterator<LocalPaymentMethod> {
        return this.methods.values();
    }

    isLocalPaymentMethod(ident: string): boolean {
        return this.methods.has(ident);
    }
}

export class GooglePay implements LocalPaymentMethod {
    ident = "googlepay";
    name = "Google Pay";
    image = "/img/brands/light/gpay.webp";
    transparentImage = "/img/brands/dark/gpay.webp";
    details: PaymentMethodDetails = {
        fields: {
            component: "FieldsGooglePay",
            fields: [],
        },
        // These fields are not used by the front-end
        currencies: [],
        countries: [],
        currency: "",
        currencySymbol: "",
        externalRedirect: false,
        checkoutAmount: 0,
    };
    countries = ["*"];

    async redirect(basketId: string, data: Record<string, any>) {
        const { href } = await router.resolve({
            name: "google-pay",
            params: { basketId },
        });

        const newWindow = popup(href, "_blank", {
            popup: true,
            toolbar: false,
            location: false,
            center: true,
            width: 610,
            height: 500,
        });

        if (!newWindow) {
            throw new Error("Failed to open new window");
        }

        newWindow.focus();

        // Add the payment data to the new window
        (newWindow as any).TBX_PAYMENT_DATA = data;
        (newWindow as any).TBX_THEME = window.xprops?.theme ?? "light";

        // Wait for the new window to close
        return new Promise<void>((resolve) => {
            const interval = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
    }

    shouldRedirect() {
        return zoid.isChild() && zoid.xprops.isEmbedded;
    }

    async isAvailable() {
        const adyen = useAdyen({ manual: true });
        const checkout = await adyen.load();
        return isGooglePayAvailable(checkout);
    }

    select(basketId: string) {
        // We need to call the get payment methods endpoint to "select" the payment method in the backend
        return getPaymentMethod(basketId, "globalcards");
    }
}

export class ApplePay implements LocalPaymentMethod {
    ident = "applepay";
    name = "Apple Pay";
    image = "/img/brands/light/applepay.webp";
    transparentImage = "/img/brands/dark/applepay.webp";
    details: PaymentMethodDetails = {
        fields: {
            component: "FieldsApplePay",
            fields: [],
        },
        // These fields are not used by the front-end
        currencies: [],
        countries: [],
        currency: "",
        currencySymbol: "",
        externalRedirect: false,
        checkoutAmount: 0,
    };
    countries = ["*"];

    async redirect(basketId: string, data: Record<string, any>) {
        const { href } = await router.resolve({
            name: "apple-pay",
            params: { basketId },
        });

        const newWindow = popup(href, "_blank", {
            popup: true,
            toolbar: false,
            location: false,
            center: true,
            width: 610,
            height: 500,
        });

        if (!newWindow) {
            throw new Error("Failed to open new window");
        }

        newWindow.focus();

        // Add the payment data to the new window
        (newWindow as any).TBX_PAYMENT_DATA = data;
        (newWindow as any).TBX_THEME = window.xprops?.theme ?? "light";

        // Wait for the new window to close
        return new Promise<void>((resolve) => {
            const interval = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
    }

    shouldRedirect() {
        return zoid.isChild() && zoid.xprops.isEmbedded;
    }

    async isAvailable() {
        const adyen = useAdyen({ manual: true });
        const checkout = await adyen.load();
        return isApplePayAvailable(checkout);
    }

    select(basketId: string) {
        // We need to call the get payment methods endpoint to "select" the payment method in the backend
        return getPaymentMethod(basketId, "globalcards");
    }
}

export const localPaymentMethodManager = new LocalPaymentMethodManager();

// Register the local payment methods
localPaymentMethodManager.addMethod(new GooglePay());
localPaymentMethodManager.addMethod(new ApplePay());
