<template>
    <v-skeleton-loader
        class="py-6"
        type="heading, list-item-avatar-two-line@3, button"
        :loading="upsellStore.shouldShowLoader"
    >
        <div class="d-flex flex-column w-100">
            <UpsellList
                v-if="upsellStore.items.length > 0"
                :items="upsellStore.items"
                v-model="upsellStore.selectedUpsells"
                :currency-code="basketStore.currencyCode"
                :loadingUpsells="upsellStore.loadingUpsells"
                @add="addUpsellToBasket"
                @remove="upsellStore.removeUpsellFromBasket"
            />
        </div>
        <div class="d-flex justify-end mt-auto">
            <v-btn color="primary" :to="next">
                {{ t("buttons.continue_to_payment") }}
            </v-btn>
        </div>

        <v-dialog v-if="dialogItem" v-model="showDialog" width="500">
            <UpsellOptions
                :upsell-item="dialogItem"
                :loading="isLoading"
                @submit="onSubmit(dialogItem)"
            ></UpsellOptions>
        </v-dialog>
    </v-skeleton-loader>
</template>

<script setup lang="ts">
// Types
import UpsellItem, { UpsellOptionValidationResponse } from "@/types/UpsellItem";

// Components
import UpsellList from "@/components/basket/UpsellList.vue";
import UpsellOptions from "@/components/basket/UpsellOptions.vue";

// Store
import { useUpsellStore } from "@/store/upsell";

// Utilities
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { type Ref, ref } from "vue";
import { useDisplay } from "vuetify";
import router from "@/plugins/router";
import { computed } from "vue";
import { useBasketStore } from "@/store/basket";
import { useMessageStore } from "@/store/message";
import { AxiosResponse, isAxiosError } from "axios";

const { t } = useI18n();
const route = useRoute();

const upsellStore = useUpsellStore();
const basketStore = useBasketStore();
const { smAndUp } = useDisplay();
const messageStore = useMessageStore();

const showDialog = ref(false);
const dialogItem: Ref<UpsellItem | null> = ref(null);
const isLoading = ref(false);

const openUpsellOptions = async (upsellItem: UpsellItem) => {
    if (smAndUp.value) {
        dialogItem.value = upsellItem;
        showDialog.value = true;
    } else {
        upsellStore.viewUpsell(upsellItem.uuid);
        // Open in new page
        router.push({
            name: "upsell-options",
            params: {
                basketId: route.params.basketId,
            },
        });
    }
};

const addUpsellToBasket = (upsellItem: UpsellItem) => {
    // If upsell has options, open options dialog
    if (upsellItem?.options.length > 0) {
        openUpsellOptions(upsellItem);
    } else {
        upsellStore.addUpsellToBasket(upsellItem);
    }
};

const onSubmit = async (upsellItem: UpsellItem) => {
    isLoading.value = true;
    const options = upsellStore.parseUpsellOptions(upsellItem);

    try {
        await upsellStore.validateUpsellOptions(upsellItem.packageId, options);
    } catch (error) {
        if (isAxiosError(error)) {
            const response =
                error.response as AxiosResponse<UpsellOptionValidationResponse>;

            messageStore.addMessage(
                response.data.detail ?? t("error.unexpected"),
                {
                    type: "error",
                },
            );
        } else {
            messageStore.addMessage(t("error.unexpected"), {
                type: "error",
            });
        }

        isLoading.value = false;
        return;
    }

    isLoading.value = false;
    showDialog.value = false;

    await upsellStore.addUpsellToBasket(upsellItem, options);
};

const next = computed(() => {
    return basketStore.address?.country === "XX"
        ? { name: "country-selection" }
        : { name: "payment" };
});
</script>
