<template>
    <i18n-t
        v-if="config?.additionalTerms.length"
        keypath="payment.privacy_terms_additional"
        for="privacy-agreement"
        scope="global"
        tag="p"
    >
        <template v-slot:privacyLink>
            <a
                href="https://checkout.tebex.io/privacy"
                target="_blank"
                class="link"
            >
                {{ t("payment.privacy_policy_header") }}
            </a>
        </template>
        <template v-slot:termsLink>
            <a
                href="https://checkout.tebex.io/terms"
                target="_blank"
                class="link"
            >
                {{ t("payment.terms_header") }}
            </a>
        </template>
        <template v-slot:additional>
            <i18n-t keypath="additional_terms" scope="global" tag="span">
                <slot
                    v-for="(term, index) in config.additionalTerms"
                    :key="term.name"
                    :name="index"
                >
                    <a :href="term.url" target="_blank" class="link">
                        {{ term.name }}
                    </a>
                </slot>
            </i18n-t>
        </template>
    </i18n-t>

    <i18n-t v-else keypath="payment.privacy_terms" tag="p" scope="global">
        <template v-slot:privacyLink>
            <a
                href="https://checkout.tebex.io/privacy"
                target="_blank"
                class="link"
            >
                {{ t("payment.privacy_policy_header") }}
            </a>
        </template>
        <template v-slot:termsLink>
            <a
                href="https://checkout.tebex.io/terms"
                target="_blank"
                class="link"
            >
                {{ t("payment.terms_header") }}
            </a>
        </template>
    </i18n-t>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { generateListFormatMessage } from "@/helpers/list-format";
import { watchEffect } from "vue";

const appStore = useAppStore();

const { config } = storeToRefs(appStore);
const { t, mergeLocaleMessage, locale } = useI18n();

watchEffect(() => {
    if (!config.value?.additionalTerms) return;

    const terms = config.value.additionalTerms.map((term) => term.name);

    mergeLocaleMessage(locale.value, {
        additional_terms: generateListFormatMessage(terms),
    });
});
</script>
