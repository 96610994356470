import popupManager from "./popup-manager";

const redirectInNewTabWithSpinner = (redirectTo: string): Window => {
    const popup = popupManager.createOrReusePopup();

    if (!popup) {
        throw Error("Window was null");
    }

    // Redirect to the payment page
    const url = new URL(window.location.href);

    url.pathname = "/redirect";
    url.searchParams.set("to", redirectTo);

    popup.location.href = url.href;

    return popup;
};

export { redirectInNewTabWithSpinner };
