<template>
    <v-sheet
        color="surface"
        class="d-flex flex-column align-center justify-center h-100"
    >
        <v-progress-circular
            :size="50"
            :color="
                theme.current.value.dark
                    ? 'rgba(255, 255, 255, 0.7)'
                    : 'rgba(0, 0, 0, 0.2)'
            "
            indeterminate
        ></v-progress-circular>
        <h4 class="my-4 title">{{ t("ui.processing") }}</h4>
    </v-sheet>
</template>

<script lang="ts" setup>
import { useRedirect } from "@/composables/useRedirect";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify/lib/framework.mjs";

interface Props {
    to?: string;
}

const props = defineProps<Props>();

const { t } = useI18n();
const theme = useTheme();
const { redirect } = useRedirect();

onMounted(() => {
    if (props.to) {
        redirect(props.to);
    }
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/theme";
.title {
    @include theme.light {
        opacity: 0.4;
    }
}

:deep(.v-progress-circular__underlay) {
    stroke: none;
}
</style>
