<template>
    <img v-if="logo" :src="logo" alt="Logo" class="logo mx-5" :style="styles" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useAppStore } from "@/store/app";
const appStore = useAppStore();

const props = withDefaults(defineProps<{ width?: number; height?: number }>(), {
    width: 0,
    height: 50,
});

const logo = computed(() => {
    if (window.xprops?.logo) {
        return window.xprops.logo;
    } else {
        return appStore.config?.logoUrl ?? "";
    }
});

const styles = computed(() => {
    const style: Record<string, string> = {};
    if (props.width) {
        style.width = `${props.width}px`;
    }
    if (props.height) {
        style.height = `${props.height}px`;
    }
    return style;
});
</script>
