<template>
    <div class="basket__codes">
        <CreatorCodeInput v-if="appStore.config?.hasCreatorCodes" />
        <CouponInput
            v-if="appStore.config?.hasCoupons || appStore.config?.hasGiftCards"
        />
    </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import CreatorCodeInput from "./CreatorCodeInput.vue";
import CouponInput from "./CouponInput.vue";

const appStore = useAppStore();
</script>
