<template>
    <div class="container">
        <div class="payment-waiting">
            <Loader class="mb-8" />

            <div class="text-center mb-11">
                {{ t("payment.3ds_loading") }}
            </div>
        </div>

        <!-- We need to open 3ds in a new tab when within tebex.js -->
        <Evervault3DSTab v-if="zoid.isChild()" :data="data" />
        <Evervault3DSModal v-else :data="data" />
    </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { SetupResponseEvervault3DSData } from "@/types/SetupResponse";
import { useZoid } from "@/composables/useZoid";

import Loader from "@/components/app/Loader.vue";
import Evervault3DSTab from "../evervault/Evervault3DSTab.vue";
import Evervault3DSModal from "../evervault/Evervault3DSModal.vue";

interface Props {
    data: SetupResponseEvervault3DSData;
}

defineProps<Props>();
const { t } = useI18n();

const zoid = useZoid();
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
}
.payment-waiting {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 475px;
}

.threeds-container {
    width: 100%;
    height: 600px;
    max-width: 500px;
    border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
    overflow: hidden;
}

:deep(iframe) {
    width: 500px !important;
    height: 600px !important;
}
</style>
