<template>
    <div class="basket__overview">
        <v-list lines="one" :aria-label="t('basket.sidebar.your_order')">
            <BasketListItem
                v-for="item in items"
                :item="item"
                :key="item.id"
                :currency-code="currencyCode"
                :tax-mode="basketStore.tax?.taxMode"
                :tax-name="basketStore.tax?.name"
            />
        </v-list>
    </div>
</template>

<script setup lang="ts">
import BasketItem from "@/types/BasketItem";
import BasketListItem from "./BasketListItem.vue";
import { useI18n } from "vue-i18n";
import { useBasketStore } from "@/store/basket";

interface Props {
    items: BasketItem[];
    currencyCode: string;
}

defineProps<Props>();

const { t } = useI18n();
const basketStore = useBasketStore();
</script>
