import { useBasketStore } from "@/store/basket";
import { createRouter, createWebHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";

import Checkout from "@/pages/Checkout.vue";
import Upsell from "@/pages/Upsell.vue";
import BasketSidebar from "@/components/basket/BasketSidebar.vue";
import BasketSummary from "@/components/basket/BasketSummary.vue";
import Country from "@/pages/Country.vue";
import Payment from "@/pages/Payment.vue";
import PaymentMethods from "@/pages/PaymentMethods.vue";
import PaymentWaiting from "@/pages/PaymentWaiting.vue";
import PaymentCompleted from "@/pages/PaymentCompleted.vue";
import PaymentError from "@/pages/PaymentError.vue";
import BasketEmpty from "@/pages/error/BasketEmpty.vue";
import BasketAlreadyComplete from "@/pages/error/BasketAlreadyComplete.vue";
import BasketNotFound from "@/pages/error/BasketNotFound.vue";
import UpsellOptionsSelection from "@/pages/UpsellOptionsSelection.vue";
import SetupResponse from "@/pages/SetupResponse.vue";
import GooglePay from "@/pages/GooglePay.vue";
import ApplePay from "@/pages/ApplePay.vue";
import Redirect from "@/pages/Redirect.vue";
import FourZeroFourPage from "@/pages/error/404.vue";
import ThreeDSecure from "@/pages/ThreeDSecure.vue";

declare module "vue-router" {
    interface RouteMeta {
        layout?: string;
        breadcrumbs?: boolean;
        width?: number;
        height?: number;
        footer?: boolean;
    }
}

export const routes = [
    {
        path: "/:basketId",
        component: Checkout,
        children: [
            {
                path: "",
                name: "basket",
                meta: { layout: "Sidebar" },
                components: {
                    default: Upsell,
                    sidebar: BasketSidebar,
                    overview: BasketSummary,
                },
            },
            {
                path: "country",
                name: "country-selection",
                meta: { layout: "Sidebar" },
                components: {
                    default: Country,
                    sidebar: BasketSidebar,
                    overview: BasketSummary,
                },
            },
            {
                path: "payment",
                name: "payment",
                meta: { layout: "Sidebar" },
                components: {
                    default: Payment,
                    sidebar: BasketSidebar,
                    overview: BasketSummary,
                },
            },
            {
                path: "select-payment-method",
                name: "select-payment-method",
                meta: { layout: "Sidebar", breadcrumbs: false },
                components: {
                    default: PaymentMethods,
                    sidebar: BasketSidebar,
                },
            },
            {
                path: "payment-waiting",
                name: "payment-waiting",
                meta: { layout: "Default", breadcrumbs: false },
                component: PaymentWaiting,
            },
            {
                path: "payment-completed",
                name: "payment-completed",
                meta: { layout: "Default", breadcrumbs: false },
                component: PaymentCompleted,
                async beforeEnter(to, from, next) {
                    const basketStore = useBasketStore();

                    await basketStore.fetchBasket(to.params.basketId as string);

                    // Check if the basket has a payment error
                    if (
                        basketStore.payment &&
                        "reason" in basketStore.payment
                    ) {
                        next({ name: "payment-error", params: to.params });
                    } else {
                        next();
                    }
                },
            },
            {
                path: "payment-error",
                name: "payment-error",
                meta: { layout: "Default", breadcrumbs: false },
                component: PaymentError,
            },
            {
                path: "empty",
                name: "empty-basket",
                meta: { layout: "Default" },
                component: BasketEmpty,
            },
            {
                path: "basket-complete",
                name: "basket-complete",
                meta: { layout: "Default" },
                component: BasketAlreadyComplete,
            },
            {
                path: "basket-not-found",
                name: "basket-not-found",
                meta: { layout: "Default" },
                component: BasketNotFound,
            },
            {
                path: "upsell-options",
                name: "upsell-options",
                meta: { layout: "Sidebar" },
                components: {
                    default: UpsellOptionsSelection,
                    sidebar: BasketSidebar,
                    overview: BasketSummary,
                },
            },
            {
                path: "setup-response",
                name: "setup-response",
                meta: { layout: "Sidebar" },
                components: {
                    default: SetupResponse,
                    sidebar: BasketSidebar,
                    overview: BasketSummary,
                },
            },
            {
                path: "3ds",
                name: "3ds",
                meta: { layout: "Default" },
                component: ThreeDSecure,
            },
            {
                path: "google-pay",
                name: "google-pay",
                meta: { layout: "Default", footer: false },
                component: GooglePay,
                beforeEnter(to, from, next) {
                    // If the payment data is not available then we need to redirect back
                    if (!(window as any).TBX_PAYMENT_DATA) {
                        next({
                            name: "basket",
                            params: { basketId: to.params.basketId },
                        });
                        return;
                    }

                    next();
                },
            },
            {
                path: "apple-pay",
                name: "apple-pay",
                meta: { layout: "Default", footer: false },
                component: ApplePay,
                beforeEnter(to, from, next) {
                    // If the payment data is not available then we need to redirect back
                    if (!(window as any).TBX_PAYMENT_DATA) {
                        next({
                            name: "basket",
                            params: { basketId: to.params.basketId },
                        });
                        return;
                    }

                    next();
                },
            },
        ],
    },
    {
        path: "/redirect",
        name: "redirect",
        props: (route) => ({ to: route.query.to }),
        component: Redirect,
    },
    {
        path: "/404",
        name: "not-found",
        component: FourZeroFourPage,
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
] as RouteRecordRaw[];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.onError((error, to) => {
    if (error.message.includes("Failed to fetch dynamically imported module")) {
        window.location.href = to.fullPath;
    }
});

export default router;
