import { useZoid } from "@/composables/useZoid";

export const useErrorPage = () => {
    const zoid = useZoid();

    const navigateBack = () => {
        if (zoid.isChild()) {
            zoid.xprops.onClosePopup();
        }
    };

    return {
        navigateBack,
    };
};
