<template>
    <div class="payment-error d-flex flex-column align-center h-100">
        <svg
            width="60"
            height="80"
            viewBox="0 0 60 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="30" cy="40" r="20" fill="#C12E2E" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.4393 31.4393C22.0251 30.8536 22.9749 30.8536 23.5607 31.4393L30 37.8787L36.4393 31.4393C37.0251 30.8536 37.9749 30.8536 38.5607 31.4393C39.1464 32.0251 39.1464 32.9749 38.5607 33.5607L32.1213 40L38.5607 46.4393C39.1464 47.0251 39.1464 47.9749 38.5607 48.5607C37.9749 49.1464 37.0251 49.1464 36.4393 48.5607L30 42.1213L23.5607 48.5607C22.9749 49.1464 22.0251 49.1464 21.4393 48.5607C20.8536 47.9749 20.8536 47.0251 21.4393 46.4393L27.8787 40L21.4393 33.5607C20.8536 32.9749 20.8536 32.0251 21.4393 31.4393Z"
                :fill="theme.current.value.dark ? '#000' : '#fff'"
            />
        </svg>

        <h1>{{ t("payment.payment_declined") }}</h1>

        <p>
            {{ t("payment.payment_declined_description") }}
        </p>

        <div v-if="methods.length > 1" class="payment-error__methods">
            <PaymentMethodSelector
                v-model="paymentMethodStore.selectedPaymentMethod"
                @change="$router.push({ name: 'payment' })"
                :items="methods"
                :cols="4"
                :loading="paymentMethodStore.items.isLoading"
                :skeleton-number="4"
                :disabled="paymentMethodStore.isPaymentMethodLoading"
                centered
            />
        </div>

        <v-btn to="/" color="primary" class="mt-8" @click="tryAgain">
            Try again
        </v-btn>
    </div>
</template>

<script setup lang="ts">
import { type Ref, ref, computed, onBeforeMount, onMounted } from "vue";
import { useTheme } from "vuetify";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useBasketStore } from "@/store/basket";
import { BasketPaymentDeclined } from "@/types/Basket";
import { useZoid } from "@/composables/useZoid";
import { usePaymentMethodStore } from "@/store/payment-method";
import { PaymentMethod } from "@/types/PaymentMethod";
import PaymentMethodSelector from "@/components/payment/PaymentMethodSelector.vue";
import { until } from "@vueuse/core";
import { storeToRefs } from "pinia";

const { t } = useI18n();
const theme = useTheme();
const basketStore = useBasketStore();
const router = useRouter();
const route = useRoute();

const basketId = route.params.basketId as string;
const payment = computed(() => basketStore.payment as BasketPaymentDeclined);

const { isReady } = storeToRefs(basketStore);

// No payment information available, redirect to basket
onBeforeMount(async () => {
    await until(isReady).toBe(true);

    if (payment.value === null) {
        router.push({
            name: "basket",
            params: { basketId },
        });
    }
});

const zoid = useZoid();

const paymentMethodStore = usePaymentMethodStore();
const methods: Ref<PaymentMethod[]> = ref([]);

onMounted(async () => {
    if (zoid.isChild()) {
        window.xprops.onPaymentError(payment.value);
    }

    methods.value = await paymentMethodStore.getRelevantPaymentMethods(
        basketStore.address?.country ?? "US",
        8,
    );
});

const tryAgain = () => {
    basketStore.setPaymentInProgress(false);
    router.push({ name: "payment" });
};
</script>

<style lang="scss" scoped>
.payment-error {
    padding: 35px;
    gap: 20px;
    text-align: center;

    &__methods {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 150px;
    }
}

p {
    max-width: 90%;
}

.payment-method-selector {
    width: 100%;
    max-width: 475px;
}
</style>
