<template>
    <span>
        <!-- information processed in new tab -->
    </span>
</template>

<script setup lang="ts">
import popupManager from "@/helpers/popup-manager";
import { useBasketStore } from "@/store/basket";
import { useMessageStore } from "@/store/message";
import { usePaymentStore } from "@/store/payment";
import { ThreeDSecureMessage } from "@/types/ThreeDSecure";
import { onBeforeUnmount, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

interface Props {
    data: any;
}

const props = defineProps<Props>();

const paymentStore = usePaymentStore();
const basketStore = useBasketStore();
const messageStore = useMessageStore();
const router = useRouter();
const { t } = useI18n();

function send3dsPayload() {
    const popup = popupManager.getPopup();

    if (!popup) {
        throw new Error("ThreeDSecure: Popup not found");
    }

    const form = paymentStore.getActiveForm();

    // Clone object
    const payload = JSON.parse(
        JSON.stringify({
            data: props.data,
            form: form?.values,
        }),
    );

    popup.postMessage(
        {
            type: "tbx_3ds_payload",
            payload,
        },
        window.location.origin,
    );
}

function onMessage(event: MessageEvent<ThreeDSecureMessage>) {
    if (event.origin !== window.location.origin) return;

    const payload = event.data;

    // When the new tab loads, we will receive this message
    // Then we can send the 3DSecure payload to the new tab
    if (payload.type === "tbx_3ds_ready") {
        send3dsPayload();
    }

    // When the 3DSecure is complete, the payment will be made within the new tab
    // So all we need to do is redirect the user to the order complete page
    if (payload.type === "tbx_3ds_complete") {
        router.push({ name: "payment-completed" });

        // Close the popup once the payment is complete
        popupManager.getPopup()?.close();
    }

    // Something went wrong with the 3DSecure, show the user an error
    if (payload.type === "tbx_3ds_failed" || payload.type === "tbx_3ds_error") {
        messageStore.addMessage(t("error.3ds_failure"), {
            type: "error",
        });
        basketStore.setPaymentInProgress(false);
        router.push({ name: "payment" });
        // Close the popup if there is an error
        popupManager.getPopup()?.close();
    }
}

function launchEvervaultInNewTab() {
    const popup = popupManager.getPopup();

    if (!popup) {
        throw new Error("ThreeDSecure: Popup not found");
    }

    // Setup event listeners to communicate with the new tab
    window.addEventListener("message", onMessage);

    // Redirect to the 3DSecure page
    const url = new URL(window.location.href);
    url.pathname = `/${basketStore.basketId}/3ds`;
    popup.location.href = url.href;
}

onMounted(() => launchEvervaultInNewTab());
onBeforeUnmount(() => window.removeEventListener("message", onMessage));
</script>
