<template>
    <v-list-item
        class="upsell__list-item pa-3 mb-3 bg-background"
        border
        role="listitem"
    >
        <template v-slot:prepend>
            <v-avatar>
                <v-img :src="item.image"></v-img>
            </v-avatar>
        </template>

        <v-list-item-title>
            <span v-if="item.quantity > 1" class="rounded-circle quantity">
                {{ item.quantity }}x
            </span>

            {{ item.name }}
        </v-list-item-title>

        <v-list-item-subtitle>
            <s v-if="price !== offerPrice" class="text-error">
                {{ price.toFixed(2) }} {{ currencyCode }}
            </s>
            {{ offerPrice.toFixed(2) }} {{ currencyCode }}
        </v-list-item-subtitle>

        <template v-slot:append>
            <v-btn
                :color="selected ? 'error' : 'secondary'"
                @click="onClick"
                :loading="loading"
            >
                {{ t(selected ? "buttons.remove" : "buttons.add") }}
            </v-btn>
        </template>
    </v-list-item>
</template>

<script setup lang="ts">
import UpsellItem from "@/types/UpsellItem";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

interface Props {
    item: UpsellItem;
    currencyCode: string;
    selected: boolean;
    loading: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(["change"]);
const { t } = useI18n();

const onClick = () => {
    emit("change", props.item);
};

const price = computed(() => {
    return props.item.price * props.item.quantity;
});

const offerPrice = computed(() => {
    return props.item.offerPrice * props.item.quantity;
});
</script>

<style lang="scss">
@use "@/styles/mixins/theme";
.quantity {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    width: 20px;
    height: 20px;
    padding: 4px;

    @include theme.dark {
        background-color: rgb(var(--v-theme-gray-10w00));
    }

    @include theme.light {
        background-color: rgb(var(--v-theme-gray-800));
        color: rgb(var(--v-theme-gray-100));
    }
}

.v-list-item-title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    gap: 6px;
}
</style>
