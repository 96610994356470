import { defineRule, configure } from "vee-validate";
import { required, email, min, max, numeric } from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import { MessageSchema } from "@/vue-i18n";
import { mergeDeep } from "@/helpers/merge-deep";
import countryList from "country-list";

import LOCALE_FALLBACK from "@vee-validate/i18n/dist/locale/en.json";

const LOCALE_VALIDATION_MAP: Record<string, string> = {
    en_US: "en",
    pt_BR: "pt_BR",
    el_GR: "el",
    lv_LV: "lv",
    it_IT: "it",
    uk_UA: "uk",
    ms_MY: "ms_MY",
    fi_FI: "fi",
    cs_CZ: "cs",
    hr_HR: "hr",
    fr_FR: "fr",
    sv_SE: "sv",
    tr_TR: "tr",
    vi_VN: "vi",
    sr_RS: "sr",
    de_DE: "de",
    ca_ES: "ca",
    ru_RU: "ru",
    ko_KR: "ko",
    hu_HU: "hu",
    da_DK: "da",
    pl_PL: "pl",
    nl_NL: "nl",
    no_NO: "nb_NO",
    ro_RO: "ro",
    es_ES: "es",
    ja_JP: "ja",
    zh_CN: "zh_CN",
    //zh_TW: "zh_TW",
};

async function getValidationMessages(locale: string) {
    try {
        const name = LOCALE_VALIDATION_MAP[locale];
        const module = await import(
            `../../node_modules/@vee-validate/i18n/dist/locale/${ name }.json`
        );
        return module.default;
    }
    catch {
        console.log(`Unable to load vee-validate messages for locale "${ locale }", defaulting to English`);
        return LOCALE_FALLBACK;
    }
}

export async function loadLocaleValidationMessages(
    locale: string,
    messages: MessageSchema,
): Promise<void> {
    const translations = await getValidationMessages(locale);

    const config = {
        [locale]: mergeDeep(translations, messages.validation),
    };

    configure({
        generateMessage: localize(config),
    });
}

defineRule("required", required);
defineRule("email", email);
defineRule("countryCode", (value: string) => {
    return countryList.getCodes().includes(value);
});
defineRule("min", min);
defineRule("max", max);
defineRule("numeric", numeric);
