import { i18n } from "@/plugins/i18n";

const defaultListTemplates = {
    single: i18n.tm("list.single"),
    two: i18n.tm("list.two"),
    start: i18n.tm("list.start"),
    middle: i18n.tm("list.middle"),
    end: i18n.tm("list.end"),
} as const;

/**
 * Generates a formatted message from a list of words using provided templates.
 *
 * @param words - An array of words to be formatted.
 * @param templates - An object containing templates for different list lengths.
 * @returns A formatted string based on the provided words and templates.
 *
 * The `templates` object should have the following keys:
 * - `single`: Template for a single word.
 * - `two`: Template for exactly two words.
 * - `start`: Template for the start of the list.
 * - `middle`: Template for the middle of the list.
 * - `end`: Template for the end of the list.
 *
 * The templates can contain placeholders `{0}`, `{1}`, etc., which will be replaced
 * by the corresponding words from the `words` array.
 *
 * Example:
 * ```typescript
 * const words = ["apple", "banana", "cherry"];
 * const templates = {
 *   single: "{0}",
 *   two: "{0} and {1}",
 *   start: "{0}, {1}",
 *   middle: "{0}, {1}",
 *   end: "{0} and {1}"
 * };
 * const message = generateListFormatMessage(words, templates);
 * console.log(message); // Output: "apple, banana, and cherry"
 * ```
 */
export function generateListFormatMessage(
    words: string[],
    templates = defaultListTemplates,
): string {
    // Get the number of words in the list
    const length: number = words.length;

    // Return an empty string if there are no words
    if (!length) {
        return "";
    }

    // Return the single template if there is only one word
    if (length === 1) {
        return templates["single"];
    }

    // Return the two template if there are exactly two words
    if (length === 2) {
        return templates["two"];
    }

    // Initialize the result with the end template, replacing the placeholder with the last word
    let result: string = replace(templates["end"], "{1}", `{${length - 1}}`);
    let currentLength: number = length;

    // Loop through the words, adding the middle template for each word except the first and last
    while (currentLength > 3) {
        currentLength--;
        const mid: string = replace(
            templates["middle"],
            "{1}",
            `{${currentLength - 1}}`,
        );
        result = replace(result, "{0}", mid);
    }

    // Replace the first placeholder with the second word
    result = replace(result, "{0}", "{1}");

    // Add the start template at the beginning of the result
    result = replace(templates["start"], "{1}", result);

    return result;
}

function replace(template: string, index: string, text: string): string {
    return template.replace(index, text);
}
