<template>
    <div id="rokt-placeholder"></div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, watchEffect } from "vue";
import { BasketPayment } from "@/types/Basket";
import { useTheme } from "vuetify/lib/framework.mjs";
import { useRokt } from "@/composables/useRokt";

interface Props {
    vendorId: string;
    payment: BasketPayment;
}

const props = defineProps<Props>();

const theme = useTheme();
const launcher = useRokt();
let selection: RoktPlacement;

watchEffect(async () => {
    if (!launcher.value) {
        return;
    }

    if (!selection) {
        selection = await launcher.value.selectPlacements({
            identifier: "rokt-placeholder",
            attributes: {
                email: props.payment.email,
                firstname: props.payment.name,
                amount: props.payment.amount,
                currency: props.payment.currency,
                VendorID: props.vendorId,
                country: props.payment.country,
                experience: theme.current.value.dark ? "dark" : "light",
            },
        });
    }
});

onBeforeUnmount(() => {
    if (selection) {
        selection.close();
    }
});
</script>
