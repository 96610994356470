import axios from "axios";

const instance = axios.create({
    baseURL:
        import.meta.env.MODE === "development" ||
        import.meta.env.MODE === "testing"
            ? "/ident"
            : import.meta.env.VITE_IDENT_API,
    headers: {
        "Content-Type": "application/json",
    },
});

export default instance;
