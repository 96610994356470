const CHECK_INTERVAL = 100;

export function watchForWindowClose(window: Window, callback: () => void) {
    const interval = setInterval(() => {
        if (window.closed) {
            clearInterval(interval);
            callback();
        }
    }, CHECK_INTERVAL);
}
