import { AppConfig } from "@/types/AppConfig";
import api from "./api";

export const getAppConfig = (
    basketId: string,
    referrer?: string,
): Promise<AppConfig> =>
    api
        .get<AppConfig>(`/baskets/${basketId}/account`, {
            params: {
                referrer,
            },
        })
        .then((t) => t.data);
