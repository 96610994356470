import { createPinia } from "pinia";
import { markRaw } from "vue";
import "pinia";
import type { Router } from "vue-router";

// adapt this based on where your router is
import router from "./router";

const pinia = createPinia();

declare module "pinia" {
    export interface PiniaCustomProperties {
        // type the router added by the plugin above
        router: Router;
    }
}

pinia.use(({ store }) => {
    store.router = markRaw(router);
});

export default pinia;
