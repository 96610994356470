<template>
    <UpsellOptions
        v-if="upsellStore.viewedUpsellItem"
        :upsell-item="upsellStore.viewedUpsellItem"
        @submit="onSubmit"
    ></UpsellOptions>
</template>

<script setup lang="ts">
import UpsellOptions from "@/components/basket/UpsellOptions.vue";
import { useUpsellStore } from "@/store/upsell";
import UpsellItem from "@/types/UpsellItem";
import { useRouter } from "vue-router";

const upsellStore = useUpsellStore();
const router = useRouter();

const onSubmit = async (upsellItem: UpsellItem) => {
    const options = upsellStore.parseUpsellOptions(upsellItem);
    await upsellStore.addUpsellToBasket(upsellItem, options);
    router.back();
};
</script>
